import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'

import { useGetRimDimensionsQuery } from 'api/truckRimDmensionApi'

import './style.css'

const TruckRimDimension = ({ selectedItem, onChange, disabled }) => {

	const { data: items, isLoading: loading } = useGetRimDimensionsQuery()

	return (
		<div className="truckRimDimension">
			<Form.Group>
				<Form.Control
					name="truckRimDimension"
					onChange={onChange}
					value={selectedItem}
					disabled={loading || disabled}
					as="select"
					size="lg"
				>
					<option value="">- Select dimension -</option>
					{items?.map((item) => (
						<option key={item.key} value={item.key}>
							{item.value}
						</option>
					))}
				</Form.Control>
			</Form.Group>
		</div>
	)
}

TruckRimDimension.propTypes = {
	selectedItem: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
}

export default TruckRimDimension
