import { apiBase } from './apiBase'
import { getQueryString } from 'lib/utils'


export const inboundDeliverysApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getPurchaseOrderDeliveries: builder.query({
			query: (criteria) => `purchaseOrderDeliveries?${getQueryString(criteria)}`,
		}),
		getSuppliers: builder.query({
			query: (criteria) => `suppliers?${getQueryString(criteria)}`,
		}),

	}),
})

export const {
	useGetPurchaseOrderDeliveriesQuery,
	useLazyGetPurchaseOrderDeliveriesQuery,
	useGetSuppliersQuery,
	useLazyGetSuppliersQuery
} = inboundDeliverysApi