import './style.css'
import React from 'react'
import PropTypes from 'prop-types'

/**
 * Print a list of label/value pairs
 * @param {*} param0
 * @returns
 */
const CardInfo = ({ items }) => {
	return (
		<div className="CardInfo">
			{items.map((item) => (
				<div key={item.value} className="item">
					<div className="label">{item.label}</div>
					<div className={`value ${item.class}`}>{item.value}</div>
				</div>
			))}
		</div>
	)
}

CardInfo.propTypes = {
	items: PropTypes.array,
	map: PropTypes.func,
}

export default CardInfo
