import { apiBase } from './apiBase'

export const stockRelocationApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		relocateStock: builder.mutation({
			query: ({ articleId, quantity, fromLocation, toLocation, dotYear }) => ({
				url: 'stockRelocation/',
				method: 'POST',
				body: {
					articleId,
					quantity,
					fromLocation,
					toLocation,
					dotYear,
				},
			}),
		}),
	}),
})

export const { useRelocateStockMutation } = stockRelocationApi