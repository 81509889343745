import './style.css'
import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const FloatingButtonWrapper = ({ children, showHome }) => {
	const navigate = useNavigate()

	const goHome = () => {
		navigate('/')
	}

	const goBack = () => {
		navigate(-1)
	}

	return (
		<div className="FloatingButtonWrapper">
			{children}
			{showHome && (
				<>
					<Button variant="warning" size="lg" onClick={goBack}>Back</Button>
					<Button variant="danger" size="lg" onClick={goHome}>Home</Button>
				</>
			)}
		</div>
	)
}

FloatingButtonWrapper.propTypes = {
	children: PropTypes.node,
	showHome: PropTypes.bool,
}

export default FloatingButtonWrapper
