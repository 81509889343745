import React from 'react'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import { StompProvider } from 'lib/stompContext'
import { createRoot } from 'react-dom/client'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import App from 'features/App/App'

if (process.env.NODE_ENV !== 'development') {
	Sentry.init({
		dsn: 'https://3f1365b25bb04622ab6fd58e11de3804@o558011.ingest.sentry.io/5693636',
		integrations: [new Integrations.BrowserTracing()],
		normalizeDepth: 10,

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
	})
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Sentry.ErrorBoundary>
				<StompProvider>
					<App />
				</StompProvider>
			</Sentry.ErrorBoundary>
		</Provider>
	</React.StrictMode>
)
