import { apiBase } from './apiBase'
import { getQueryString } from 'lib/utils'

export const brandsApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getBrands: builder.query({
			query: (criteria) => `brands?${getQueryString(criteria)}`

		}),
	}),
})

export const {
	useGetBrandsQuery,
	useLazyGetBrandsQuery,
} = brandsApi