import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import './NewVersion.css'

const NewVersion = ({ onReload }) => {
	return (
		<div className="NewVersion">
			<Alert variant="success">
				<p>New version available!</p>
				<hr />
				<div className="d-flex justify-content-end">
					<Button onClick={onReload} variant="outline-success">
						Reload page
					</Button>
				</div>
			</Alert>
		</div>
	)
}

export default NewVersion
