import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FloatingWindow from 'components/FloatingWindow'
import LoadingOverlay from 'components/LoadingOverlay'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import StockAddForm from 'components/StockAddForm'
import { usePerformStockLocationAddMutation } from 'api/stockLocationAddApi'
import { useLazyGetArticleQuery } from 'api/article'
import { setValue, resetState } from 'features/ArticleStockLocationAdd/articleStockLocationAddSlice'
import { showMessage } from 'features/MessageBox/duck'
import propTypes from 'prop-types'

const ArticleStockLocationAdd = ({ onClose, articleId, newLocation = '' }) => {
	const dispatch = useDispatch()
	const {
		title,
		articleId: stateArticleId,
		article,
		newQuantity,
		stockLocation,
		dotYear,
	} = useSelector((state) => state.articleStockLocationAdd)

	const [performStockLocationAdd, { isLoading }] = usePerformStockLocationAddMutation()
	const [triggerFetchArticle, { data: fetchedArticle, isFetching: isArticleLoading }] = useLazyGetArticleQuery()

	const onSubmit = async (e) => {

		const payload = {
			articleId: stateArticleId,
			quantity: newQuantity,
			stockLocation: stockLocation,
			dotYear: dotYear,
		}

		try {
			await performStockLocationAdd(payload).unwrap()

			dispatch(showMessage('Success', 'Data successfully updated'))
			dispatch(resetState())
			onClose()
		} catch (err) {
			dispatch(showMessage('Error', err || 'Failed to add stock.'))
		}
	}

	const onChange = (name, value) => {
		dispatch(setValue({ name, value }))

		if (name === 'articleId') {
			triggerFetchArticle(value)
		}
	}

	useEffect(() => {
		if (newLocation) {
			dispatch(setValue({ name: 'stockLocation', value: newLocation }))
		}
	}, [newLocation, dispatch])

	useEffect(() => {
		if (fetchedArticle) {
			dispatch(setValue({ name: 'article', value: fetchedArticle }))
		}
	}, [fetchedArticle, dispatch])

	const loadingSpinner = isLoading || isArticleLoading ? <LoadingOverlay /> : ''

	return (
		<FloatingWindow
			title={title}
			showHeader
			draggable={false}
			closeOutSide
			onClose={onClose}
		>
			<Container>
				<Row className="justify-content-center">
					<StockAddForm
						onSubmit={onSubmit}
						onBack={onClose}
						onChange={onChange}
						articleId={stateArticleId}
						article={article}
						newQuantity={newQuantity}
						stockLocation={stockLocation}
						dotYear={dotYear}
					/>
				</Row>
				{loadingSpinner}
			</Container>
		</FloatingWindow>
	)
}

ArticleStockLocationAdd.propTypes = {
	articleId: propTypes.number,
	newLocation: propTypes.string,
	onClose: propTypes.func,
}

export default ArticleStockLocationAdd