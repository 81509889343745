import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const ArticleIdInfo = ({ articleId, supplierArticleId }) => {
	return (
		<div className="articleIdsInfo">
			<div className="articleIds header">
				<span>ArticleId</span>
				<span>SupplierArticleId</span>
			</div>
			<div className="articleIds">
				<span>{articleId}</span>
				<span>{supplierArticleId ? supplierArticleId : 'N/A'}</span>
			</div>
		</div>
	)
}

ArticleIdInfo.propTypes = {
	articleId: PropTypes.number.isRequired,
	supplierArticleId: PropTypes.string,
}

export default ArticleIdInfo
