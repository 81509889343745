import React from 'react'
import articleType from 'enums/articleType'
import ArticleTypeButton from './ArticleTypeButton'

import './style.css'

const ArticleTypeButtons = ({ onClick, active }) => {
	return (
		<div className="grid-container">
			<div className="grid-item">
				<ArticleTypeButton
					text="PCR"
					articleType={articleType.tyre}
					active={active}
					onClick={onClick}
				/>
			</div>
			<div className="grid-item">
				<ArticleTypeButton
					text="TBR"
					articleType={articleType.truckTyre}
					active={active}
					onClick={onClick}
				/>
			</div>
			<div className="grid-item">
				<ArticleTypeButton
					text="OTR"
					articleType={articleType.loadTyre}
					active={active}
					onClick={onClick}
				/>
			</div>
			<div className="grid-item">
				<ArticleTypeButton
					text="PCW"
					articleType={articleType.rim}
					active={active}
					onClick={onClick}
				/>
			</div>
			<div className="grid-item">
				<ArticleTypeButton
					text="TBW / OTW"
					articleType={articleType.truckRims}
					active={active}
					onClick={onClick}
				/>
			</div>
			<div className="grid-item">
				<ArticleTypeButton
					text="MOTO"
					articleType={articleType.MOTO}
					active={active}
					onClick={onClick}
				/>
			</div>
		</div>
	)
}

export default ArticleTypeButtons
