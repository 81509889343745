import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Card, Col, Row, Form, Button, Container } from 'react-bootstrap'
import './style.css'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import ArticleLocationInfo from 'components/ArticleLocationInfo'
import ArticleIdInfo from 'components/ArticleIdInfo'
import LoadingOverlay from 'components/LoadingOverlay'
import { useNavigate, useParams } from 'react-router-dom'
import NumpadInput from 'components/NumpadInput/NumpadInput'
import ConfirmationWindow from 'components/ConfirmationWindow/ConfirmationWindow'
import ArticleStockLocationAdd from 'features/ArticleStockLocationAdd/articleStockLocationAdd'
import classNames from 'classnames'

import { setValue } from '../ArticleStockLocationAdd/articleStockLocationAddSlice'
import PropTypes from 'prop-types'
import { showMessage } from '../MessageBox/duck'

import {
	useGetStockTakingQuery,
	useLazyGetStockTakingQuery,
	useSaveNewStockTakingMutation,
	useProcessStockTakingMutation
} from 'api/stockTakingApi'

const StockTakingRow = ({
	item,
	onChange,
	onToggleCheck,
	isProcessed,
	isChecked,
}) => {
	const onChangeHandler = (name, value) => {
		onChange(item.id, name, value)
	}

	const onToggleCheckHandler = () => {
		onToggleCheck(item.id)
	}

	return (
		<Card className="stock-card" onClick={onToggleCheckHandler}>
			<Card.Body className={classNames({ rowChecked: isChecked })}>
				<div className="articleText">{item.articleText}</div>
				<ArticleIdInfo
					articleId={item.articleId}
					supplierArticleId={item.supplierArticleId}
				/>
				<ArticleLocationInfo
					location={item.stockLocation}
					quantity={item.quantity}
				/>

				<div className="input row">
					<div className="group">
						<Form.Group controlId={`quantity-${item.id}`}>
							<NumpadInput
								label="New Quantity"
								name="newQuantity"
								type="number"
								value={item.newQuantity}
								onChange={onChangeHandler}
								disabled={isProcessed}
							/>
						</Form.Group>
					</div>
					<div className="group">
						<Form.Group controlId={`dotyear-${item.id}`}>
							<NumpadInput
								label="DOT"
								name="dotYear"
								type="number"
								value={item.dotYear}
								onChange={onChangeHandler}
								disabled={isProcessed}
							/>
						</Form.Group>
					</div>
				</div>
			</Card.Body>
		</Card>
	)
}

const StockTaking = () => {
	const dispatch = useDispatch()
	const { sequenceNumber } = useParams()
	const [showConfirmation, setShowConfirmation] = useState(false)
	const [checkedRowIds, setCheckedRowIds] = useState([])
	const [showAddArticleToLocaton, setShowAddArticleToLocation] = useState(false)

	const { data: stockTaking, isLoading: loading } = useGetStockTakingQuery({ id: sequenceNumber })
	const [triggerGetStockTaking] = useLazyGetStockTakingQuery()
	const [triggerProcessStockTaking] = useProcessStockTakingMutation()
	const [triggerSaveNewStockTaking] = useSaveNewStockTakingMutation()

	const [modifiedStockTaking, setModifiedStockTaking] = useState(stockTaking)
	const [isProcessed, setIsProcessed] = useState(false)
	const [isDirty, setIsDirty] = useState(false)

	const navigate = useNavigate()
	const submit = useRef(false)

	const updateStockTakingRow = ({ rowId, name, value }) => {
		const updatedRows = modifiedStockTaking.rows.map((item) => {
			if (item.id === rowId) {
				return {
					...item,
					[name]: value,
				}
			}
			return item
		})

		const updatedStockTaking = {
			...stockTaking,
			rows: updatedRows,
		}
		setIsDirty(true)
		setModifiedStockTaking(updatedStockTaking)
	}

	const onChange = (rowId, name, value) => {
		updateStockTakingRow({ rowId, name, value })
	}

	const onToggleCheck = (rowId) => {
		if (checkedRowIds.includes(rowId)) {
			setCheckedRowIds(checkedRowIds.filter((id) => id !== rowId))
		} else {
			setCheckedRowIds([...checkedRowIds, rowId])
		}
	}

	const sendProcessStockTaking = async () => {
		submit.current = true
		setShowConfirmation(false)

		try {
			const response = await triggerProcessStockTaking({ sequenceNumber: stockTaking.sequenceNumber }).unwrap()
			if (response === null) {
				dispatch(showMessage('Info', 'Stock taking has been processed successfully'))
				setIsProcessed(true)
				navigate(-1)
			} else {
				dispatch(showMessage('Error', 'Unexpected response from the server'))
			}
		} catch (error) {
			dispatch(showMessage('Error', 'Failed to process stock taking'))
		}
	}

	const onCompleteStockTakingClick = () => {
		if (!loading)
			setShowConfirmation(true)
	}

	const onAddArticleToLocation = () => {
		if (!loading) {
			dispatch(setValue('completed', false))
			setShowAddArticleToLocation(true)
		}
	}

	const onCloseAddArticleToLocation = () => {
		triggerGetStockTaking({ id: sequenceNumber })
		setShowAddArticleToLocation(false)
	}

	const onSave = () => {
		const payload = {
			sequenceNumber: sequenceNumber,
			rows: modifiedStockTaking.rows.map((item) => ({
				id: item.id,
				newStockLocation: item.newStockLocation,
				newQuantity: item.newQuantity,
				dotYear: item.dotYear,
				comment: item.comment,
			})),
		}

		triggerSaveNewStockTaking(payload)
		setIsProcessed(true)
		setIsDirty(false)
	}

	useEffect(() => {
		setModifiedStockTaking(stockTaking)
	}, [stockTaking])

	return (
		<>
			<Container className="StockTaking">
				{modifiedStockTaking && !loading && (
					<div className="StockTakingView">
						<div>
							SequenceNumber: <strong>{sequenceNumber}</strong>
						</div>
						<Row className="justify-content-center">
							<Col xs={12} sm={12}>
								<Row className="justify-content-center">
									{modifiedStockTaking.rows?.length &&
										modifiedStockTaking.rows.map((item) => (
											<StockTakingRow
												key={item.id}
												item={item}
												onChange={onChange}
												onToggleCheck={onToggleCheck}
												isChecked={checkedRowIds.includes(item.id)}
												isProcessed={isProcessed}
											/>
										))}
								</Row>
							</Col>
						</Row>
					</div>
				)}
				{loading && <LoadingOverlay />}
				{showConfirmation && (
					<ConfirmationWindow
						title="Confirmation"
						message={`Are you sure you want to complete #${sequenceNumber}?`}
						onCancel={() => setShowConfirmation(false)}
						onConfirm={sendProcessStockTaking}
					/>
				)}
			</Container>
			<FloatingButtonWrapper showHome={true}>
				<Button
					variant="primary"
					size="lg"
					onClick={onAddArticleToLocation}
					className={loading ? 'disabled' : ''}
				>
					Add article to location
				</Button>

				<>
					{isDirty ? (
						<Button variant="primary" size="lg" onClick={onSave}>
							Save
						</Button>
					) : (
						<Button
							variant="success"
							size="lg"
							onClick={onCompleteStockTakingClick}
							className={loading ? 'disabled' : ''}
						>
							Complete
						</Button>
					)}
				</>

			</FloatingButtonWrapper>
			{showAddArticleToLocaton && (
				<ArticleStockLocationAdd onClose={onCloseAddArticleToLocation} />
			)}
		</>
	)
}

StockTakingRow.propTypes = {
	item: PropTypes.object,
	onChange: PropTypes.func,
	onToggleCheck: PropTypes.func,
	isProcessed: PropTypes.bool,
	isChecked: PropTypes.bool,
}

export default StockTaking
