import React from 'react'

import { Button } from 'react-bootstrap'
import FloatingWindow from 'components/FloatingWindow'

const ConcurrencyNotification = ({ onClose }) => {
	return (
		<FloatingWindow
			closeOutSide={false}
			fullSize={true}
			showHeader={true}
			draggable={false}
		>
			<div>
				{'Data has been modified by another user, reloading data'}
				<Button variant="dark" size="md" onClick={onClose}>
					OK
				</Button>
			</div>
		</FloatingWindow>
	)
}

export default ConcurrencyNotification
