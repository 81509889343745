import * as React from 'react'
const SvgBarcodes = (props) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={24}
		height={20}
		fill="currentColor"
		className="barcodes_svg__bi barcodes_svg__bi-barcodes"
		viewBox="0 0 24 24"
		{...props}
	>
		<path d="M2 6h2v12H2zm3 0h1v12H5zm2 0h3v12H7zm4 0h1v12h-1zm3 0h2v12h-2zm3 0h3v12h-3zm4 0h1v12h-1z" />
	</svg>
)
export default SvgBarcodes
