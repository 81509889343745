import { apiBase } from './apiBase'

export const loginApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		sendLogin: builder.mutation({
			query: ({ username, password }) => ({
				url: 'Account',
				method: 'POST',
				body: { username, password },
			}),
		}),
		checkLogin: builder.query({
			query: () => ({
				url: 'Account',
				method: 'GET',
			}),
		}),
		logout: builder.mutation({
			query: () => ({
				url: 'Account',
				method: 'DELETE',
			}),
		}),
		fetchUser: builder.query({
			query: () => ({
				url: 'User',
				method: 'GET',
			}),
		}),
	}),
})

// Export hooks for usage in functional components
export const {
	useSendLoginMutation,
	useCheckLoginQuery,
	useLogoutMutation,
	useFetchUserQuery,
	useLazyCheckLoginQuery,
	useLazyFetchUserQuery
} = loginApi