import { useCallback, useRef } from 'react'

const useLongPress = (
	onLongPress,
	{ shouldPreventDefault = true, delay = 300 } = {}
) => {
	const timeout = useRef()
	const target = useRef()

	const preventDefault = useCallback((event) => {
		if (event.touches?.length < 2 && event.preventDefault)
			event.preventDefault()
	}, [])

	const start = useCallback(
		(event) => {
			if (shouldPreventDefault && event.target) {
				event.target.addEventListener('touchend', preventDefault, { passive: false })
				target.current = event.target
			}
			timeout.current = setTimeout(() => {
				onLongPress(event)
			}, delay)
		},
		[onLongPress, delay, shouldPreventDefault, preventDefault]
	)

	const clear = useCallback(
		(event, shouldTriggerClick = true) => {
			clearTimeout(timeout.current)

			if (shouldPreventDefault && target.current) {
				target.current.removeEventListener('touchend', preventDefault)
				target.current = null
			}
		},
		[shouldPreventDefault, preventDefault]
	)

	return {
		onMouseDown: start,
		onTouchStart: start,
		onMouseUp: clear,
		onMouseLeave: (e) => clear(e, false),
		onTouchEnd: clear
	}
}

export default useLongPress