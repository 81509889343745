import React from 'react'
import { useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import './style.css'
import { useLogoutMutation } from 'api/loginApi'

const Start = () => {
	const navigate = useNavigate()
	const featureSettings = useSelector(
		(state) => state.app.settings.featureSettings,
	)
	const user = useSelector(
		(state) => state.login.user
	)
	const [triggerLogout] = useLogoutMutation()

	const onNavigationClicked = (historyString) => {
		navigate(`/${historyString}`)
	}

	const onLogout = () => {
		triggerLogout()
	}

	return (
		<div className="start">
			{user?.hasArticlePrivileges && <Button
				variant="danger"
				block
				onClick={() => onNavigationClicked('stocklocation')}
			>
				Stocklocation
			</Button>}
			{user?.hasArticlePrivileges && <Button
				variant="dark"
				block
				onClick={() => onNavigationClicked('articlesearch')}
			>
				Product
			</Button>}
			{user?.hasInboundDeliveryPrivileges &&
				<Button
					variant="secondary"
					block
					onClick={() => onNavigationClicked('inboundDelivery')}
				>
					Inbound delivery
				</Button>}

			{(featureSettings?.enablePickingCategory && user?.hasPickingPrivileges) && (
				<Button
					variant="primary"
					block
					onClick={() => onNavigationClicked('picking')}
				>
					Picking
				</Button>
			)}
			{user?.hasRollingInventoryPrivileges && <Button
				variant="warning"
				block
				onClick={() => onNavigationClicked('rollingInventory')}
			>
				Rolling inventory
			</Button>}
			<Button variant="info" block onClick={onLogout}>
				Logout
			</Button>
		</div>
	)
}

export default Start
