import { apiBase } from './apiBase'

export const truckRimDimensionApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getRimDimensions: builder.query({
			query: () => 'truckRimDimensions'
		}),
	}),
})

export const {
	useGetRimDimensionsQuery,
	useLazyGetRimDimensionsQuery
} = truckRimDimensionApi