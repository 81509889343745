import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import PropTypes from 'prop-types'

const PrivateRoute = ({
	authenticated: isAuthenticated,
	initialCheck: isChecked,
	redirectTo,
	...rest
}) => {
	if (!isChecked)
		return null

	if (!isAuthenticated)
		return <Navigate to={redirectTo || '/login'} replace={true} />

	return <Outlet />
}

PrivateRoute.propTypes = {
	authenticated: PropTypes.bool,
	initialCheck: PropTypes.bool,
	redirectTo: PropTypes.string,
}

export default PrivateRoute
