import { apiBase } from './apiBase'
import { getQueryString } from 'lib/utils'

export const rollingInventoryApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getStockLocationReviewList: builder.query({
			query: (criteria) => `StockLocationReviewList?${getQueryString(criteria)}`,
		}),
		getStockLocationFilterList: builder.query({
			query: () => 'StockLocationReviewFilter',
		}),
	}),
})

export const {
	useGetStockLocationReviewListQuery,
	useLazyGetStockLocationReviewListQuery,
	useGetStockLocationFilterListQuery,
	useLazyGetStockLocationFilterListQuery
} = rollingInventoryApi