import { apiBase } from './apiBase'

export const truckDimensionsApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getTruckTyreDimensions: builder.query({
			query: () => 'truckTyreDimensions'
		}),
	}),
})

export const {
	useGetTruckTyreDimensionsQuery,
	useLazyGetTruckTyreDimensionsQuery,
} = truckDimensionsApi