import { useRef, useEffect } from 'react'

const usePhysicalScanner = (onScanned) => {
	const barcode = useRef('')

	const scanning = useRef(false)

	const handleKeyDown = (event) => {
		if (event.key === 'Control') {
			scanning.current = true
		} else if (scanning.current) {
			if (event.key === 'b') {
				barcode.current = ''
			} else if (event.key === 'Enter') {
				scanning.current = false
				onScanned(barcode.current)
			} else {
				barcode.current += event.key
			}
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
}

export default usePhysicalScanner
