import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const ArticleLocationInfo = ({ quantity, location }) => {
	return (
		<div className="articleLocationInfo">
			<div className="locationInfo header">
				<span>{location && 'Location'}</span>
				<span>{quantity && 'Quantity'}</span>
			</div>
			<div className="locationInfo">
				<span>{location}</span>
				<span>{quantity}</span>
			</div>
		</div>
	)
}

ArticleLocationInfo.propTypes = {
	quantity: PropTypes.number,
	location: PropTypes.string,
}

export default ArticleLocationInfo
