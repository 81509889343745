import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import LoadingOverlay from 'components/LoadingOverlay'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'
import { useLazyFetchSettingsQuery } from 'api/appApi'
import { useLazyFetchUserQuery, useSendLoginMutation } from 'api/loginApi'

const Login = ({
	loading,
	invalidLogin,
	loggedIn
}) => {
	const [formData, setFormData] = useState({
		username: '',
		password: ''
	})

	const [triggerFetchSettings] = useLazyFetchSettingsQuery()
	const [triggerFetchUser] = useLazyFetchUserQuery()
	const [triggerLogin] = useSendLoginMutation()

	const { username, password } = formData
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onChange = (e) => {
		const { name, value } = e.target
		setFormData({
			...formData,
			[name]: value
		})
	}

	const onSubmit = (e) => {
		e.preventDefault()
		triggerLogin({username, password})
	}

	useEffect(() => {
		if (loggedIn) {
			triggerFetchUser()
			triggerFetchSettings()
			navigate('/')
		}
	}, [loggedIn, dispatch, navigate, triggerFetchSettings, triggerFetchUser])

	return (
		<Container>
			{invalidLogin ? <Alert variant="danger">Invalid login</Alert> : ''}
			<Row className="justify-content-center">
				<Col xs={12} sm={6}>
					<Form onSubmit={onSubmit}>
						<Form.Group controlId="formBasicEmail">
							<Form.Label>Username</Form.Label>
							<Form.Control
								type="text"
								name="username"
								placeholder="Signatur"
								onChange={onChange}
								value={username}
								required
							/>
						</Form.Group>

						<Form.Group controlId="formBasicPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								name="password"
								placeholder="Password"
								onChange={onChange}
								value={password}
								required
							/>
						</Form.Group>

						<Button variant="success" type="submit">
							Login
						</Button>
						{loading ? <LoadingOverlay /> : ''}
					</Form>
				</Col>
			</Row>
		</Container>
	)
}

Login.propTypes = {
	loading: PropTypes.bool.isRequired,
	invalidLogin: PropTypes.bool.isRequired,
	loggedIn: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
	loading: state.login.loading,
	invalidLogin: state.login.invalidLogin,
	loggedIn: state.login.loggedIn,
	initialCheck: state.login.initialCheck
})

export default connect(mapStateToProps)(Login)