import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FloatingWindow from 'components/FloatingWindow'
import { Button } from 'react-bootstrap'
import { hideMessage } from './duck'

const MessageBox = () => {
	const dispatch = useDispatch()
	const { title, message } = useSelector((state) => state.messageBox)

	const onClose = () => {
		dispatch(hideMessage())
	}

	return (
		<FloatingWindow
			title={title}
			showHeader={true}
			draggable={false}
			closeOutSide={true}
			onClose={onClose}
		>
			<div>{message}</div>
			<div>
				<Button onClick={onClose}>OK</Button>
			</div>
		</FloatingWindow>
	)
}

export default MessageBox
