import './style.css'
import React from 'react'
import { Form } from 'react-bootstrap'
import FloatingNumpad from 'components/FloatingNumpad'
import PropTypes from 'prop-types'

const NumpadInput = ({
	label,
	name,
	type,
	value,
	onChange,
	showStockButton = false,
	disabled = false,
	onCloseNumpad,
	error,
}) => {
	const [showNumpad, setShowNumpad] = React.useState(false)

	const onChangeHandler = (e) => {
		onChange(e.target.name, e.target.value)
	}

	const onNumpadChange = (value) => {
		onChange(name, value)
	}

	const onClickHandler = () => {
		if (disabled) return
		setShowNumpad(true)
	}

	const onClear = () => {
		onChange(name, '')
	}

	const onSendClicked = (e) => {
		if (e) e.preventDefault()

		setShowNumpad(false)

		if (onCloseNumpad) onCloseNumpad()
	}

	return (
		<div className="numpadInput">
			<Form.Label className="label">{label}</Form.Label>
			<Form.Control
				name={name}
				type={type}
				className="editable"
				value={value}
				onChange={onChangeHandler}
				onClick={onClickHandler}
				disabled={disabled}
				readOnly
			/>
			{error && <Form.Text className="error">{error}</Form.Text>}
			{showNumpad && (
				<FloatingNumpad
					headerText={label}
					text={value.toString()}
					onChange={onNumpadChange}
					onSend={onSendClicked}
					onClear={onClear}
					showStockButton={showStockButton}
					hideExtraButton={!showStockButton}
				/>
			)}
		</div>
	)
}

NumpadInput.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	type: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func,
	showStockButton: PropTypes.bool,
	disabled: PropTypes.bool,
	onCloseNumpad: PropTypes.func,
}

export default NumpadInput
