import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArticleTitle from 'components/ArticleTitle'
import StockChangesForm from 'components/StockChangesForm'
import LoadingOverlay from 'components/LoadingOverlay'
import { getPrefixIfUserWarehouse } from 'lib/utils'
import { useNavigate, useParams } from 'react-router'

import { showMessage } from 'features/MessageBox/duck'
import { useLazyGetArticleQuery } from 'api/article'
import { useLazyGetStockLocationQuery, useLazyValidateStockLocationQuery } from 'api/stockLocationApi'
import { useRelocateStockMutation } from 'api/stockRelocationApi'

const MoveArticle = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { articleId, stockLocation, dotYear } = useParams()

	const [criteria, setCriteria] = React.useState({
		loading: false,
		article: null,
		moveQuantity: '',
		newLocation: '',
	})

	const user = useSelector(state => state.login.user)

	const [triggerFetchArticle, { data: article, isFetching: isArticleLoading }] = useLazyGetArticleQuery()
	const [triggerFetchStock, { data: stockInfo, isFetching: isStockLoading }] = useLazyGetStockLocationQuery()
	const [triggerValidateStockLocation, { isFetching: isStockLocationValidating }] = useLazyValidateStockLocationQuery()
	const [relocateStock, { isFetching: isRelocating }] = useRelocateStockMutation()

	const onGoBackClicked = () => {
		navigate(-1)
	}

	const onChange = (name, value) => {
		setCriteria({ ...criteria, [name]: value })
	}

	const onSubmit = async (e) => {
		if (e) e.preventDefault()

		const toLocation = getPrefixIfUserWarehouse(user, criteria.newLocation)

		if (!criteria.moveQuantity.trim().length || !criteria.newLocation.trim().length) return

		try {
			const validationResult = await triggerValidateStockLocation({ fromStockLocation: stockLocation, toStockLocation: toLocation }).unwrap()

			if (!validationResult) {
				showInvalidStockLocation()
				return
			}

			await relocateStock({
				articleId: article.articleId,
				quantity: criteria.moveQuantity,
				fromLocation: stockLocation,
				toLocation: toLocation,
				dotYear: dotYear,
			}).unwrap()

			dispatch(showMessage('Success', 'Stock relocation successful!'))
			triggerFetchStock({ articleId, stockLocation, dotYear })
		} catch (err) {
			const errorMessage = err?.data?.message || err.message || 'An unknown error occurred'
			if (errorMessage.includes('Invalid location'))
				showInvalidStockLocation()
			else
				dispatch(showMessage('Error', `Stock relocation failed: ${errorMessage}`))
		}
	}

	const showInvalidStockLocation = () => {
		dispatch(showMessage('Invalid location', 'Unable to move quantity to another warehouse!'))
	}

	useEffect(() => {
		triggerFetchArticle(articleId)
		triggerFetchStock({ articleId, stockLocation, dotYear })
	}, [articleId, stockLocation, dotYear, triggerFetchArticle, triggerFetchStock])

	const loadingSpinner = (isArticleLoading || isStockLoading || isStockLocationValidating || isRelocating) ? <LoadingOverlay /> : ''

	return (
		<Container>
			<Row className="justify-content-center">
				<Col xs={12} sm={12}>
					<Row className="justify-content-center">
						<Card style={{ width: '32rem' }}>
							<Card.Header>Move Article</Card.Header>
							<Card.Body>
								{article && <ArticleTitle item={article} dotYear={dotYear} />}
							</Card.Body>
						</Card>
					</Row>
				</Col>
			</Row>

			<Row className="justify-content-center">
				<StockChangesForm
					onSubmit={onSubmit}
					onBack={onGoBackClicked}
					isStockCorrection={false}
					onChange={onChange}
					currentQuantity={stockInfo?.quantityInStock || 0}
					currentlyInPicking={stockInfo?.quantityReserved || 0}
					moveQuantity={criteria.moveQuantity}
					newLocation={criteria.newLocation}
				/>
			</Row>

			{loadingSpinner}
		</Container>
	)
}

export default MoveArticle
