import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArticleTitle from 'components/ArticleTitle'
import LoadingOverlay from 'components/LoadingOverlay'
import StockChangesForm from 'components/StockChangesForm'

import { usePostNewStockQuantityMutation } from '../../api/stockCorrectionApi'
import { useGetArticleQuery, useLazyGetArticleQuery } from '../../api/article'
import { useGetStockLocationQuery, useLazyGetStockLocationQuery } from 'api/stockLocationApi'
import { showMessage } from 'features/MessageBox/duck'
import { usePrevious } from 'lib/utils'

const StockCorrection = () => {
	const [newQuantity, setNewQuantity] = useState('')
	const [loading, setLoading] = useState(false)
	const [completed, setCompleted] = useState(false)
	const [error, setError] = useState(false)

	const dispatch = useDispatch()

	const { articleId, stockLocation, dotYear } = useParams()
	const navigate = useNavigate()

	const { data: article } = useGetArticleQuery(articleId)
	const { data: stockQuantity } = useGetStockLocationQuery({ articleId, stockLocation, dotYear })
	const [postNewStockQuantity] = usePostNewStockQuantityMutation()
	const [triggerGetStockLocationData] = useLazyGetStockLocationQuery()
	const [triggerGetArticleData] = useLazyGetArticleQuery()

	const prevCompleted = usePrevious(completed)
	const prevError = usePrevious(error)

	const onGoBackClicked = () => {
		navigate(-1)
	}

	const onChange = (name, value) => {
		setNewQuantity(value)
	}

	const onSubmit = async (e) => {
		e.preventDefault()

		if (!newQuantity.trim().length) {
			return
		}

		if (article != null) {
			try {
				setLoading(true)
				await postNewStockQuantity({ articleId: article.articleId, quantity: newQuantity, stockLocation, dotYear }).unwrap()
				setCompleted(true)
			} catch {
				setError(true)
			} finally {
				setLoading(false)
			}
		}
	}

	const showError = useCallback(() => {
		if (error) {
			dispatch(showMessage('Error', 'Unable to perform correction'))
		}
	}, [error, dispatch])

	const fetchArticleStock = useCallback(async () => {
		try {
			triggerGetStockLocationData({ articleId, stockLocation, dotYear }).unwrap()
		} catch {
			setError(true)
		}
	}, [articleId, stockLocation, dotYear, triggerGetStockLocationData])

	useEffect(() => {
		if (!prevCompleted && completed) fetchArticleStock()
		if (!prevError && error) showError()
	}, [completed, error, prevCompleted, prevError, fetchArticleStock, showError])

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				await triggerGetArticleData(articleId).unwrap()
				await triggerGetStockLocationData({ articleId, stockLocation, dotYear }).unwrap()
			} catch {
				setError(true)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [articleId, stockLocation, dotYear, triggerGetArticleData, triggerGetStockLocationData])

	const loadingSpinner = loading ? <LoadingOverlay /> : ''

	return (
		<Container>
			<Row className="justify-content-center">
				<Col xs={12} sm={12}>
					<Row className="justify-content-center">
						<Card style={{ width: '32rem' }}>
							<Card.Header>Correct quantity</Card.Header>
							<Card.Body>
								{article && <ArticleTitle item={article} dotYear={dotYear} />}
							</Card.Body>
						</Card>
					</Row>
				</Col>
			</Row>

			<Row className="justify-content-center">
				<StockChangesForm
					onSubmit={onSubmit}
					onBack={onGoBackClicked}
					onChange={onChange}
					currentQuantity={stockQuantity?.quantityInStock || 0}
					currentlyInPicking={stockQuantity?.quantityReserved || 0}
					newQuantity={newQuantity}
					isStockCorrection={true}
				/>
			</Row>

			{loadingSpinner}
		</Container>
	)
}

export default StockCorrection
