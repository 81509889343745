import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import './style.css'

const LoadingSpinner = () => (
	<div className="loadingSpinner">
		<Spinner animation="border" role="status">
			<span className="sr-only">Loading...</span>
		</Spinner>
	</div>
)

export default LoadingSpinner
