import React, { PureComponent } from 'react'

import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import { Alert } from 'react-bootstrap'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import If from 'components/If'

import './style.css'
import FloatingNumpad from 'components/FloatingNumpad'

export class StockChangesForm extends PureComponent {
	constructor(props) {
		super(props)

		this.state = {
			editing: null,
			error: false,
		}
	}

	static propTypes = {
		onChange: PropTypes.func.isRequired,
		currentQuantity: PropTypes.number.isRequired,
		newQuantity: PropTypes.string,
		newLocation: PropTypes.string,
		moveQuantity: PropTypes.string,
		currentlyInPicking: PropTypes.number,
		isStockCorrection: PropTypes.bool,
		onSubmit: PropTypes.func.isRequired,
		onBack: PropTypes.func.isRequired,
	}

	onSubmit = (item) => {
		this.props.onSubmit(item)
	}

	onBack = () => {
		this.props.onBack()
	}

	onChange = (e) => {
		const { name, value } = e.target

		this.props.onChange(name, value)
	}

	onEdit = (e) => {
		this.setState({
			editing: e.target.name,
		})
	}

	onNumpadChange = (value) => {
		const { editing } = this.state

		this.props.onChange(editing, value)
	}

	onConfirmNumpad = () => {
		this.setState({
			editing: null,
		})
	}

	onClearNumpad = () => {
		const { editing } = this.state

		this.props.onChange(editing, '')
	}

	componentDidUpdate(prevProps, prevState) {
		let invalidQuantity =
			!this.props.isStockCorrection &&
			this.props.moveQuantity >
				this.props.currentQuantity - this.props.currentlyInPicking
		this.setState({
			error: invalidQuantity,
		})
	}

	render() {
		const {
			isStockCorrection,
			currentQuantity,
			newQuantity,
			newLocation,
			moveQuantity,
			currentlyInPicking,
		} = this.props

		const { editing, error } = this.state

		const errorText = 'Quantity not available'
		const currentValue = this.props[editing]
		const showStockButton = editing && editing === 'newLocation'

		return (
			<Container className="stockChangesForm">
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<Form onSubmit={this.onSubmit}>
							<Form.Group>
								<Row className="justify-content-center">
									<Form.Label column="xxl">Quantity</Form.Label>
									<Col xs={5}>
										<Form.Control
											size="xxl"
											type="text"
											readOnly
											placeholder={currentQuantity}
										/>
									</Col>
								</Row>
							</Form.Group>
							<Form.Group>
								<Row className="justify-content-center">
									<Form.Label column="xxl">In picking</Form.Label>
									<Col xs={5}>
										<Form.Control
											size="xxl"
											type="text"
											readOnly
											placeholder={currentlyInPicking}
										/>
									</Col>
								</Row>
							</Form.Group>
							<If condition={isStockCorrection}>
								<Form.Group>
									<Row className="justify-content-center">
										<Form.Label column="xxl">New quantity</Form.Label>
										<Col xs={5}>
											<Form.Control
												size="xxl"
												type="number"
												className="editable"
												onChange={this.onChange}
												name="newQuantity"
												onClick={this.onEdit}
												readOnly
												value={newQuantity}
											/>
										</Col>
									</Row>
								</Form.Group>
							</If>
							<If condition={!isStockCorrection}>
								<Form.Group>
									<Row className="justify-content-center">
										<Form.Label column="xxl">Move quantity</Form.Label>
										<Col xs={5}>
											<Form.Control
												size="xxl"
												type="number"
												className="editable"
												onChange={this.onChange}
												name="moveQuantity"
												onClick={this.onEdit}
												readOnly
												value={moveQuantity}
											/>
										</Col>
									</Row>
								</Form.Group>
							</If>

							<If condition={!isStockCorrection}>
								<Form.Group>
									<Row className="justify-content-center">
										<Form.Label column="xxl">New location</Form.Label>
										<Col xs={5}>
											<Form.Control
												size="xxl"
												type="text"
												className="editable"
												onChange={this.onChange}
												name="newLocation"
												onClick={this.onEdit}
												readOnly
												value={newLocation}
											/>
										</Col>
									</Row>
								</Form.Group>
							</If>
							<If condition={error}>
								<Row>
									<Alert style={{ width: '100%' }} variant="danger">
										{errorText}
									</Alert>
								</Row>
							</If>

							<Row className="justify-content-center">
								<Button
									variant="success"
									disabled={error}
									size="lg"
									type="submit"
								>
									Save
								</Button>
							</Row>

							<Row className="justify-content-center">
								<Button variant="danger" size="lg" onClick={this.onBack}>
									Back
								</Button>
							</Row>
						</Form>
					</Col>
				</Row>

				{editing ? (
					<FloatingNumpad
						onChange={this.onNumpadChange}
						text={currentValue}
						onSend={this.onConfirmNumpad}
						onClear={this.onClearNumpad}
						showStockButton={showStockButton}
						hideExtraButton={!showStockButton}
					/>
				) : (
					''
				)}
			</Container>
		)
	}
}

export default StockChangesForm
