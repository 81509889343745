import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	title: 'Add article to stock location',
	loading: false,
	articleId: '',
	article: '',
	newQuantity: '',
	stockLocation: '',
	dotYear: '',
}

const articleStockLocationAddSlice = createSlice({
	name: 'articleStockLocationAdd',
	initialState,
	reducers: {
		setValue: (state, action) => {
			const { name, value } = action.payload
			state[name] = value
		},
		resetState: () => initialState,
	},
})

export const { setValue, setCompleted, setError, resetState } = articleStockLocationAddSlice.actions

export default articleStockLocationAddSlice.reducer