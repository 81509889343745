import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './style.css'
import { Form } from 'react-bootstrap'
import Numpad from 'components/Numpad'

export default class RimDimension extends Component {
	static propTypes = {
		onChange: PropTypes.func.isRequired,
		width: PropTypes.any,
		diameter: PropTypes.any,
		bolts: PropTypes.any,
		circle: PropTypes.any,
		inchSearch: PropTypes.bool,
		onSubmit: PropTypes.func,
	}

	constructor(props) {
		super(props)
		this.state = {
			editing: 0,
			diameterLength: 2,
			diameterInchLength: 2,
			widthLength: 2,
			widthInchLength: 3,
			boltsLength: 1,
			boltsInchLength: 1,
			circleLength: 5,
			circleInchLength: 5,
			inchSearch: PropTypes.bool,
		}

		this.inputs = ['width', 'diameter', 'bolts', 'circle']
	}


	onSetValue = (value, keyCode) => {
		if (!value && keyCode !== 8) {
			return
		}

		const { editing } = this.state

		const { diameter, width, bolts, circle, inchSearch } = this.props

		const editingName = this.inputs[editing]

		let setInchSearch = inchSearch
		let newValue
		let currentEdit = ''

		const maxLength =
			this.state[editingName + (setInchSearch ? 'Inch' : '') + 'Length']
		setInchSearch = value === '.' ? true : inchSearch

		if (this.props[editingName].length === 0 && value === '0') {
			currentEdit = editing + 1
		} else if (keyCode !== 8) {
			newValue = this.props[editingName] + value

			if (editingName !== this.inputs[this.inputs.length - 1]) {
				currentEdit = newValue.length >= maxLength ? editing + 1 : editing
			}
		}

		if (keyCode === 8 && editingName)
			newValue = this.props[editingName].slice(0, -1)

		if (
			newValue?.length === 0 &&
			this.props[editingName].length === 0 &&
			editing !== 0
		)
			currentEdit = editing - 1

		if (currentEdit !== '') {
			this.setState({
				editing: currentEdit,
			})
		}

		if (newValue?.length <= maxLength) {
			this.props.onChange({
				diameter,
				width,
				bolts,
				circle,
				[editingName]: newValue,
				inchSearch: setInchSearch,
			})
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.width.length > this.props.width.length) {
			this.setState({
				editing: 0,
			})
		}
	}

	render() {
		const { width, diameter, bolts, circle } = this.props

		const editingName = this.inputs[this.state.editing]

		return (
			<>
				<div className="rimDimension">
					<div>
						<input
							name="width"
							className={classNames({ active: editingName === 'width' })}
							value={width}
							onChange={this.onChangeDummy}
							disabled
						/>
						<span>x</span>
						<input
							name="diameter"
							value={diameter}
							className={classNames({ active: editingName === 'diameter' })}
							onChange={this.onChangeDummy}
							disabled
						/>
					</div>
					<div>
						<input
							name="bolts"
							value={bolts}
							className={classNames({ active: editingName === 'bolts' })}
							onChange={this.onChangeDummy}
							disabled
						/>
						<span>/</span>
						<input
							name="circle"
							className={classNames({ active: editingName === 'circle' })}
							value={circle}
							onChange={this.onChangeDummy}
							disabled
						/>
					</div>

				</div>
				{
					this.props.showNumpad &&
					<Form.Group onSubmit={this.props.onSubmit} >
						<Numpad
							onClick={this.onSetValue}
							onSend={(e) => this.props.onSubmit(e)}
							showStockButton={false}
							allowCharacters={false}
							buttonText="Search"
							hideExtraButton={true}
						/>
					</Form.Group>
				}
			</>
		)
	}
}
