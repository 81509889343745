import { apiBase } from './apiBase'

export const stockLocationBulkMoveApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		performStockLocationMove: builder.mutation({
			query: (criteria) => ({
				url: 'stockBulkRelocation',
				method: 'POST',
				body: criteria,
			}),
		}),
	}),
})

export const {
	usePerformStockLocationMoveMutation,
} = stockLocationBulkMoveApi