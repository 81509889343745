import { apiBase } from './apiBase'

export const stockLocationAddApi = apiBase.injectEndpoints({
	reducerPath: 'stockLocationAddApi',
	endpoints: (builder) => ({
		performStockLocationAdd: builder.mutation({
			query: (criteria) => ({
				url: 'stockLocationAddArticle/',
				method: 'POST',
				body: criteria,
			}),
		}),
	}),
})

export const { usePerformStockLocationAddMutation } = stockLocationAddApi