import { apiBase } from './apiBase'

export const inboundOrderApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		fetchPurchaseOrderDelivery: builder.query({
			query: (sequenceNumber) => ({
				url: `purchaseOrderDelivery?sequenceNumber=${sequenceNumber}`,
				method: 'GET',
			}),
		}),
		savePurchaseOrderDelivery: builder.mutation({
			query: (payload) => ({
				url: 'purchaseOrderDelivery',
				method: 'PUT',
				body: payload,
			}),
		}),
		completeOrderDelivery: builder.mutation({
			query: (payload) => ({
				url: 'purchaseOrderDelivery',
				method: 'POST',
				body: payload,
			}),
		}),
		deleteInboundOrderRow: builder.mutation({
			query: ({ sequenceNumber, rowId }) => ({
				url: 'purchaseOrderDelivery',
				method: 'DELETE',
				body: { sequenceNumber, rowId },
			}),
		}),
	}),
})

export const {
	useFetchPurchaseOrderDeliveryQuery,
	useLazyFetchPurchaseOrderDeliveryQuery,
	useSavePurchaseOrderDeliveryMutation,
	useCompleteOrderDeliveryMutation,
	useDeleteInboundOrderRowMutation,
} = inboundOrderApi