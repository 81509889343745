import './style.css'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import { Alert, Button } from 'react-bootstrap'
import ArticleTitle from 'components/ArticleTitle'
import ArticleIdInfo from 'components/ArticleIdInfo'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import ArticleLocationInfo from 'components/ArticleLocationInfo'
import barcodes from '../ArticleSearch/assets/barcodes.svg'
import BarCodeScanner from 'features/BarCodeScanner'
import { toggleShowScanner } from 'features/BarCodeScanner/barCodeScannerSlice'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import classNames from 'classnames'
import useStockTakingCreator from 'lib/hooks/useStockTakingCreator'
import StockLocationMove from '../StockLocationMove'
import If from 'components/If'
import { useLazyGetArticlesByCriteriaQuery } from 'api/article'
import { useLazyGetStockLocationArticlesQuery } from 'api/stockLocationSearchApi'
import LoadingSpinner from 'components/LoadingSpinner'

const ArticleList = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()

	const { searchType } = useParams()

	const criteria = location.state
	const showScanner = useSelector((state) => state.barCodeScanner.showScanner)
	const { loadingStocking, createStockTakingByArticleIds } = useStockTakingCreator()

	const [bulkRelocation, setBulkRelocation] = React.useState(null)
	const inStockTaking = bulkRelocation?.some((article) => article.moveStatus === 1)
	const [moveLocation, setMoveLocation] = React.useState(false)

	const [triggerGetArticles, { data: articleSearch, isLoading: articlesLoading }] = useLazyGetArticlesByCriteriaQuery()
	const [triggerGetStockLocationArticles, { data: locationSearch, isFetching: stockLocationLoading }] = useLazyGetStockLocationArticlesQuery()

	const articles = searchType === 'article' ? articleSearch : locationSearch


	const onMoveLocation = () => {
		setMoveLocation(true)
	}

	const onNewScanResult = (articleId) => {
		const article = (articles)?.find(
			(item) => item.articleId === Number(articleId),
		)
		onArticleClicked(article)
	}

	const onArticleClicked = (article) => {
		if (article) {
			if (searchType === 'article')
				navigate(`/stocklocations/${article.articleId}`)
			else
				navigate(`/stocklocations/${article.articleId}/${criteria}`)
		}
	}

	const onBulkLocationChange = (e) => {

		if (Array.isArray(articles)) {
			const articlesWithMoveStatus = articles.map((article) => ({
				...article,
				moveStatus: 1,
			}))
			setBulkRelocation(articlesWithMoveStatus)
		}
	}

	const onArticleClickBulkRelocation = (article) => {
		const updatedArticles = bulkRelocation.map((item) => {
			if (item.articleId === article.articleId) {
				return {
					...item,
					moveStatus: item.moveStatus === 1 ? 0 : 1,
				}
			}
			return item
		})
		setBulkRelocation(updatedArticles)
	}

	const onCancelStockTaking = () => {
		if (Array.isArray(articles)) {
			const articlesWithMoveStatus = articles.map((article) => ({
				...article,
				moveStatus: 0,
			}))
			setBulkRelocation(articlesWithMoveStatus)
		}
	}

	const onCreateStockTakingList = () => {
		const selectedArticleIds = bulkRelocation
			.filter((article) => article.moveStatus === 1)
			.map((article) => article.articleId)

		createStockTakingByArticleIds(selectedArticleIds, criteria)
	}

	const toggleScanner = () => {
		dispatch(toggleShowScanner())
	}

	useEffect(() => {
		if (criteria && Object.keys(criteria).length > 0) {
			if (searchType === 'article') {
				triggerGetArticles(criteria.payload)
			} else if (searchType === 'location') {
				triggerGetStockLocationArticles(criteria)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [criteria, searchType])

	const loading = articlesLoading || stockLocationLoading

	return (
		<>
			<Container className="articleList">
				{
					loading && <LoadingSpinner />
				}
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						{articles && !articles.length > 0 && !loading ? (<Alert variant="danger">No articles found</Alert>) : ''}
						<Row className="justify-content-center">
							{articles
								? articles.map((item) => (
									<Card
										key={item.articleId}
										onClick={() => {
											const isAnyArticlePicked = bulkRelocation?.some(
												(article) => article.moveStatus === 1,
											)
											if (!isAnyArticlePicked) {
												onArticleClicked(item)
											} else {
												onArticleClickBulkRelocation(item)
											}
										}}
									>
										<Card.Body
											className={classNames({
												notFound:
													bulkRelocation?.find(
														(article) =>
															article.articleId === item.articleId,
													)?.moveStatus === 0,
												picked:
													bulkRelocation?.find(
														(article) =>
															article.articleId === item.articleId,
													)?.moveStatus === 1,
											})}
										>
											<ArticleTitle item={item} />
											<ArticleLocationInfo
												quantity={item.quantityInStock}
											/>
											<ArticleIdInfo
												articleId={item.articleId}
												supplierArticleId={item.supplierArticleId}
											/>
										</Card.Body>
									</Card>
								))
								: ''}
						</Row>
					</Col>
				</Row>
				{showScanner && <BarCodeScanner onScannedResult={onNewScanResult} />}
			</Container>
			<FloatingButtonWrapper showHome={true}>
				{inStockTaking && (
					<Button
						variant="success"
						size="lg"
						onClick={onCreateStockTakingList}
						disabled={loadingStocking}
					>
						Create stocktaking
					</Button>
				)}
				{inStockTaking && (
					<Button
						variant="warning"
						size="lg"
						onClick={onCancelStockTaking}
						disabled={loadingStocking}
					>
						Cancel stocktaking
					</Button>
				)}
				{!inStockTaking && searchType === 'location' && (
					<Button variant="success" size="lg" onClick={onBulkLocationChange}>
						Stocktaking
					</Button>
				)}
				{!inStockTaking && searchType === 'location' && (
					<Button variant="warning" size="lg" onClick={onMoveLocation}>
						Move Location
					</Button>
				)}
				{articles?.length > 1 && !inStockTaking && (
					<Button variant="secondary" size="lg" onClick={toggleScanner}>
						<img src={barcodes} alt="barcode input" />
					</Button>
				)}
			</FloatingButtonWrapper>
			<If condition={moveLocation}>
				<StockLocationMove
					currentStockLocation={criteria}
					newStockLocation=""
					articles={articles}
				/>
			</If>

		</>
	)
}

export default ArticleList
