const purchaseOrderDeliveryStatus = {
	none: 0,
	ready: 1,
	delivered: 2,
	dropShipping: 3,
}

export default purchaseOrderDeliveryStatus

export const getPurchaseOrderDeliveryStatus = (status) => {
	switch (status) {
		case purchaseOrderDeliveryStatus.none:
			return 'None'
		case purchaseOrderDeliveryStatus.ready:
			return 'Ready'
		case purchaseOrderDeliveryStatus.delivered:
			return 'Delivered'
		case purchaseOrderDeliveryStatus.dropShipping:
			return 'Dropshipping'
		default:
			return 'None'
	}
}
