import { apiBase } from './apiBase'

export const activeMqSettingsApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getActiveMqSettings: builder.query({
			query: () => 'activeMqSettings',
		}),
	}),
})

export const {
	useGetActiveMqSettingsQuery,
	useLazyGetActiveMqSettingsQuery
} = activeMqSettingsApi