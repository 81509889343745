import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './style.css'

const FloatingFilterButton = ({
	positionX = 'right',
	positionY = 'top',
	btnState,
	onClick,
	title
}) => {
	const [buttonState, setButtonState] = useState('closed')
	const onClickHandler = () => {
		onClick()
		onButtonHandler()
	}

	const onButtonHandler = () => {
		setButtonState(buttonState === 'closed' ? 'open' : 'closed')
	}

	useEffect(() => {
		if (btnState !== undefined) {
			setButtonState(btnState ? 'open' : 'closed')
		}
	}, [btnState])

	return (
		<div className={
			classNames('FloatingFilterButton', positionX, positionY)}>
			<Button
				variant={buttonState === 'closed' ? 'info' : 'danger'}
				size="lg"
				onClick={onClickHandler}>
				{title || <div>
					<div className={classNames('horizontal-line', 'first', buttonState)}></div>
					<div className={classNames('horizontal-line', 'second', buttonState)}></div>
					<div className={classNames('horizontal-line', 'third', buttonState)}></div>
				</div>}
			</Button>
		</div>
	)
}

FloatingFilterButton.propTypes = {
	positionX: PropTypes.string,
	positionY: PropTypes.string,
	onClick: PropTypes.func,
	btnState: PropTypes.bool,
	title: PropTypes.string
}

export default FloatingFilterButton