import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	supplier: '',
	ean: '',
	supplierArticleNumber: '',
	waybillNumber: '',
	supplierOrderNumber: '',
	sort: 1,
	inboundDeliveryType: 'delivery',
}

const inboundDeliverySlice = createSlice({
	name: 'inboundDelivery',
	initialState,
	reducers: {
		setValue: (state, action) => {
			const { name, value } = action.payload
			state[name] = value
		},
		resetState: () => initialState,
	},
})

export const { setValue, setCompleted, setError, resetState } = inboundDeliverySlice.actions

export default inboundDeliverySlice.reducer