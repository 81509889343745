const initialState = {
	title: '',
	message: '',
	showMessage: false,
}

const MESSAGE_BOX_SHOW = 'features/MessageBox/MESSAGE_BOX_SHOW'
const MESSAGE_BOX_HIDE = 'features/MessageBox/MESSAGE_BOX_HIDE'

export const showMessage = (title, message) => {
	return {
		type: MESSAGE_BOX_SHOW,
		title,
		message,
	}
}

export const hideMessage = () => {
	return {
		type: MESSAGE_BOX_HIDE,
	}
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case MESSAGE_BOX_SHOW:
			return {
				...state,
				title: action.title,
				message: action.message,
				showMessage: true,
			}

		case MESSAGE_BOX_HIDE:
			return {
				...state,
				showMessage: false,
				message: '',
			}

		default:
			return state
	}
}
export default reducer
