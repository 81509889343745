import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	showScanner: false,
}

const barCodeScannerSlice = createSlice({
	name: 'barCodeScanner',
	initialState,
	reducers: {
		toggleShowScanner: (state) => {
			state.showScanner = !state.showScanner
		},
	},
})

// Export actions
export const { toggleShowScanner } = barCodeScannerSlice.actions

// Export reducer
export default barCodeScannerSlice.reducer