import { apiBase } from './apiBase'
import { getQueryString } from 'lib/utils'

export const articleApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getArticle: builder.query({
			query: (articleId) => `article/${articleId}`,
			providesTags: (result, error, articleId) => [{ type: 'article', id: articleId }],
			//keepUnusedDataFor: 60
		}),
		getArticlesByCriteria: builder.query({
			query: (criteria) => `articles?${getQueryString(criteria)}`
		}),
		getArticlesBySearchText: builder.query({
			query: (articleSearchText) => `articles?articleSearchText=${articleSearchText}`
		}),
	}),
})

export const {
	useGetArticleQuery,
	useLazyGetArticleQuery,
	useGetArticlesByCriteriaQuery,
	useLazyGetArticlesByCriteriaQuery,
	useGetArticlesBySearchTextQuery,
	useLazyGetArticlesBySearchTextQuery,
} = articleApi