import React from 'react'
import PropTypes from 'prop-types'

import './style.css'

const SupplierInfo = ({ supplier, supplierOrderId }) => {
	return (
		<div className="supplierInfo">
			<div className="supplier header">
				<span>Supplier</span>
				{
					supplierOrderId &&
          <span>Supplier order id</span>
				}
			</div>
			<div className="supplier">
				<span>{supplier}</span>
				{
					supplierOrderId &&
          <span>{supplierOrderId}</span>
				}
			</div>
		</div>
	)
}

SupplierInfo.propTypes = {
	supplier: PropTypes.string.isRequired,
	supplierOrderId: PropTypes.string,
}

export default SupplierInfo