import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const InformationBox = ({ message }) => {
	return (
		<div className="InformationBox">
			{message}
		</div>
	)
}

InformationBox.propTypes = {
	message: PropTypes.string.isRequired,
}

export default InformationBox