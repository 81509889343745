import React from 'react'
import './style.css'

const ConnectionError = () => {

	return (
		<div className="connectionError">
			<div className="connectionError">
				Connection issue! Reconnecting...
			</div>
		</div>
	)
}

export default ConnectionError