import { COMMON_LOGGEDOUT } from './commonTypes'

const unauthorizedMiddleware = ({ dispatch }) =>
	(next) =>
		(action) => {
			const { error, message } = action

			//Unauthorized error
			if (error && message === '401') {
				dispatch({
					type: COMMON_LOGGEDOUT,
				})
			}

			return next(action)
		}
export default unauthorizedMiddleware
