import { apiBase } from './apiBase'

export const stockCorrectionApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		postNewStockQuantity: builder.mutation({
			query: ({ articleId, quantity, stockLocation, dotYear }) => ({
				url: 'stockCorrection/',
				method: 'POST',
				body: ({
					articleId,
					quantity,
					stockLocation,
					dotYear
				}),
			}),
		}),
	}),
})

export const {
	usePostNewStockQuantityMutation,
} = stockCorrectionApi