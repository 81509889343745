const initialState = {
	loading: false,
	articles: null,
}

const FETCH_ARTICLES = 'features/MoveArticle/FETCH_ARTICLES'
const FETCH_ARTICLES_SUCCESS = 'features/MoveArticle/FETCH_ARTICLES_SUCCESS'
const FETCH_ARTICLES_FAILED = 'features/MoveArticle/FETCH_ARTICLES_FAILED'

//Actions
export const fetchArticles = (articleId, stockLocation) => {
	const data = {
		articleId,
		stockLocation,
	}

	return {
		types: [FETCH_ARTICLES, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILED],
		callApi: {
			endpoint: 'api/ArticleStockLocation',
			method: 'GET',
			data: data,
		},
	}
}

export const fetchArticlesById = (articleId) => {
	return {
		types: [FETCH_ARTICLES, FETCH_ARTICLES_SUCCESS, FETCH_ARTICLES_FAILED],
		callApi: {
			endpoint: 'api/ArticleStockLocation/' + articleId,
			method: 'GET',
		},
	}
}

//Reducer
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_ARTICLES:
			return {
				...state,
				loading: true,
				articles: null,
			}
		case FETCH_ARTICLES_SUCCESS:
			return {
				...state,
				loading: false,
				articles: action.data,
			}
		case FETCH_ARTICLES_FAILED:
			return {
				...state,
				loading: false,
				articles: null,
			}
		default:
			return state
	}
}
