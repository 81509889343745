import { apiBase } from './apiBase'
import { getQueryString } from 'lib/utils'


export const stockTakingApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getStockTaking: builder.query({
			query: (criteria) => `stockTaking?${getQueryString(criteria)}`,
		}),
		createNewStockTaking: builder.mutation({
			query: (criteria) => ({
				url: 'stockTaking',
				method: 'POST',
				body: criteria, // Send criteria in the request body
			}),
		}),
		saveNewStockTaking: builder.mutation({
			query: (criteria) => ({
				url: 'stockTaking',
				method: 'PUT',
				body: criteria,
			}),
		}),
		processStockTaking: builder.mutation({
			query: (criteria) => ({
				url: 'stockTakingProcess',
				method: 'POST',
				body: criteria,
			}),
		}),

	}),
})

export const {
	useGetStockTakingQuery,
	useLazyGetStockTakingQuery,
	useCreateNewStockTakingMutation,
	useSaveNewStockTakingMutation,
	useProcessStockTakingMutation
} = stockTakingApi