import './style.css'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import React, { useCallback, useEffect, useRef } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import LoadingOverlay from 'components/LoadingOverlay'
import CardInfo from 'components/CardInfo/CardInfo'
import dayjs from 'dayjs'
import classNames from 'classnames'
import useStockTakingCreator from 'lib/hooks/useStockTakingCreator'
import PropTypes from 'prop-types'
import FloatingFilterButton from 'components/FloatingFilterButton'
import FloatingWindow from 'components/FloatingWindow'
import If from 'components/If'
import RollingInventorySearchForm from 'components/RollingInventorySearchForm'
import _ from 'lodash'
import { useLazyGetStockLocationFilterListQuery, useLazyGetStockLocationReviewListQuery } from 'api/rollingInventory'

import { showMessage } from '../MessageBox/duck'

const RollingInventoryRow = ({ item, onClick, isChecked, className }) => {
	const settings = useSelector((state) => state.app.settings)

	const currentYear = new Date().getFullYear().toString()
	const rollingYear = dayjs(item.lastChanged).format('YYYY')
	let locationTextColor

	if (rollingYear === currentYear) {
		locationTextColor = 'greenText'
	} else {
		locationTextColor = 'redText'
	}

	return (
		<Card
			onClick={() => onClick(item.stockLocation)}
			className={classNames('rolling-card', { rowChecked: isChecked }, className)}
		>
			<Card.Body>
				<CardInfo
					items={[
						{
							label: 'Location',
							value: item.formattedStockLocation,
							class: locationTextColor,
						},
						{
							label: 'Last review',
							value: dayjs(item.lastChanged).format(settings.shortDateFormat),
						},
					]}
				/>
			</Card.Body>
		</Card>
	)
}

const RollingInventory = () => {
	const dispatch = useDispatch()

	const [criteria, setCriteria] = React.useState({
		sortType: '1',
		selectedWarehouse: '',
		selectedHall: '',
		selectedAisle: '',
		selectedPlace: '',
		selectedHeight: '',
		userWarehouseId: null,
		warehouseFilterRef: null
	})

	const [triggerGetLocations, { data: locations, isLoading: loading }] = useLazyGetStockLocationReviewListQuery()
	const [triggerGetFilterList, { data: warehouseFilterRef }] = useLazyGetStockLocationFilterListQuery()

	const userWarehouseId = useSelector((state) => state.login.user?.warehouseId)

	const [selectedLocations, setSelectedLocations] = React.useState([])
	const { loadingStockTaking, createStockTakingByLocations } = useStockTakingCreator()

	const previousCriteria = useRef(criteria)

	const [filter, setFilter] = React.useState(false)
	const submit = useRef(false)

	const onToggleLocation = (stockLocation) => {
		if (selectedLocations.includes(stockLocation)) {
			setSelectedLocations(
				selectedLocations.filter((item) => item !== stockLocation),
			)
		} else {
			setSelectedLocations([...selectedLocations, stockLocation])
		}
	}

	const onFilterClicked = () => {
		submit.current = false
		onFilter()
	}

	const onFilter = useCallback(() => {
		setFilter(!filter)
	}, [filter, setFilter])

	const onSubmit = (e) => {
		if (e) e.preventDefault()
		submit.current = true

		if (!_.isEqual(criteria, previousCriteria.current)) {
			triggerGetLocations({
				sortType: criteria.sortType,
				warehouseId: criteria.selectedWarehouse,
				hallNumber: criteria.selectedHall,
				aisleNumber: criteria.selectedAisle,
				placeNumber: criteria.selectedPlace,
				heightNumber: criteria.selectedHeight
			})
		} else {
			onFilter()
		}
	}

	const onChange = (name, value) => {
		setCriteria(prevCriteria => ({
			...prevCriteria,
			[name]: value
		}))
	}

	const onClickCreate = () => {
		createStockTakingByLocations(selectedLocations)
	}

	useEffect(() => {
		if (userWarehouseId) {
			setCriteria(prev => ({
				...prev,
				selectedWarehouse: userWarehouseId,
			}))
		}
	}, [userWarehouseId])

	useEffect(() => {
		triggerGetLocations({ sortType: criteria.sortType, warehouseId: null })
		triggerGetFilterList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (filter && locations?.length > 0 && submit.current) {
			onFilter()
		}
	}, [filter, locations, onFilter])

	useEffect(() => {
		if (locations?.length === 0 && submit.current) {
			dispatch(showMessage('Info', 'No locations found'))
		}
	}, [locations, dispatch])

	return (
		<>
			<Container className="RollingInventory">
				{locations && !loading && (
					<Row className="">
						<Col xs={12} sm={12}>
							<Row className="">
								{locations.map((location) => (
									<RollingInventoryRow
										key={location.stockLocation}
										item={location}
										onClick={onToggleLocation}
										isChecked={selectedLocations.includes(
											location.stockLocation,
										)}
									/>
								))}
							</Row>
						</Col>
					</Row>
				)}
				{(loading || loadingStockTaking) && <LoadingOverlay />}
			</Container>

			<If condition={filter}>
				<FloatingWindow className="justify-content-center" closeOutSide={false} draggable={false} showHeader={true} fullSize={true}>
					<RollingInventorySearchForm
						onSubmit={onSubmit}
						onChange={onChange}
						selectedWarehouse={criteria.selectedWarehouse}
						userWarehouseId={userWarehouseId}
						selectedHall={criteria.selectedHall}
						selectedAisle={criteria.selectedAisle}
						selectedPlace={criteria.selectedPlace}
						selectedHeight={criteria.selectedHeight}
						sortType={criteria.sortType}
						warehouseFilterRef={warehouseFilterRef}
					/>
				</FloatingWindow>
			</If>

			<FloatingButtonWrapper showHome={true}>
				<FloatingFilterButton
					positionX='middle'
					positionY='bottom'
					btnState={filter}
					onClick={onFilterClicked}
					title='Filter and Search'
				/>
				{selectedLocations.length > 0 && (
					<Button variant="primary" size="lg" onClick={onClickCreate}>
						Create stock taking
					</Button>
				)}
			</FloatingButtonWrapper>
		</>
	)
}

RollingInventoryRow.propTypes = {
	item: PropTypes.object,
	onClick: PropTypes.func,
	isChecked: PropTypes.bool,
	className: PropTypes.string,
}

export default RollingInventory