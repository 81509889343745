import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NumpadButton from './NumpadButton'
import backspace from './Asset/backspace.svg'

import './style.css'

export default class Numpad extends Component {
	static propTypes = {
		onClick: PropTypes.func.isRequired,
		onClear: PropTypes.func,
		onSend: PropTypes.func.isRequired,
		showStockButton: PropTypes.bool.isRequired,
		hideExtraButton: PropTypes.bool.isRequired,
		buttonText: PropTypes.string.isRequired,
		disabled: PropTypes.bool,
		allowCharacters: PropTypes.bool,
	}

	constructor(props) {
		super(props)
		this.focusDummy = React.createRef()
	}

	groups = [
		[1, 2, 3],
		[4, 5, 6],
		[7, 8, 9],
	]

	onClick = (value, keyCode) => {
		if (keyCode === 17) {
			this.props.onClear()
			return
		}
		this.props.onClick(value, keyCode)
	}

	onSendClicked = () => {
		this.props.onSend()
	}

	handleBackSpace = (e) => {
		const value = e.target.value
		this.props.onClick(value, 8)
	}

	onKeybaordInput = (e) => {
		e.preventDefault()
		if (e.keyCode !== 13 && this.props.disabled) return

		this.focusDummy.current.focus()

		if (e.keyCode === 13) {
			this.onSendClicked()
			return
		}

		if (document.activeElement && document.activeElement.tagName !== 'INPUT')
			this.focusDummy.current.focus()
		if (e.key?.length !== 1) this.onClick(null, e.keyCode)
		else if (e.key === '.' || e.key === ',') this.onClick('.', e.keyCode)
		else if (!isNaN(e.key) || this.props.allowCharacters)
			this.onClick(e.key, e.keyCode)
	}

	componentDidMount() {
		window.addEventListener('keyup', this.onKeybaordInput)
	}

	componentWillUnmount() {
		window.removeEventListener('keyup', this.onKeybaordInput)
	}

	render() {
		const { buttonText, disabled } = this.props

		let stockButton
		if (this.props.hideExtraButton)
			stockButton = (<NumpadButton value="" onClick={this.onClick} disabled={disabled} />)
		else if (this.props.showStockButton)
			stockButton = (<NumpadButton value="v" onClick={this.onClick} disabled={disabled} />)
		else
			stockButton = (<NumpadButton value="." onClick={this.onClick} disabled={disabled} />)

		return (
			<div className="numpad">
				{this.groups.map((group, index) => (
					<div key={index} className="group">
						{group.map((number) => (
							<NumpadButton
								key={number}
								value={number}
								onClick={this.onClick}
								disabled={disabled}
							/>
						))}
					</div>
				))}

				<div className="group">
					{stockButton}
					<NumpadButton value="0" onClick={this.onClick} disabled={disabled} />
					<button type="button" value="<" onClick={this.handleBackSpace} disabled={disabled}> <img src={backspace} alt='<' /> </button>

				</div>
				<button className="search" type="submit">
					{buttonText}
				</button>

				<button type="button" className="focus" ref={this.focusDummy}></button>
			</div>
		)
	}
}
