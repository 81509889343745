import React from 'react'
import FloatingWindow from 'components/FloatingWindow'
import { Button } from 'react-bootstrap'
import './style.css'
import PropTypes from 'prop-types'

const SelectRowCorrection = ({
	onClickRemoveAmount,
	onClickRemoveRow,
	title,
	onClose,
	disableRemoveAmount
}) => {

	return (
		<div className="SelectRowCorrection" >
			<FloatingWindow
				title={title}
				showHeader={true}
				draggable={false}
				closeOutSide={true}
				onClose={onClose}
			>
				<div>
					{
						disableRemoveAmount ?
							'No alternative location found for this article, remove row from list?' : 'No alternative location found for this article. Row can either be removed from list or amount on row can be adjusted to found amount'
					}
				</div>
				<div className="optionBtns">
					{
						!disableRemoveAmount &&
						<Button variant='warning' onClick={onClickRemoveAmount}>{'Remove missing amount from pickinglistrow'}</Button>
					}
					<Button variant='dark' onClick={onClickRemoveRow}>{'Remove pickinglistrow from pickinglist'}</Button>
				</div>
			</FloatingWindow>
		</div>
	)
}

SelectRowCorrection.propTypes = {
	onClickRemoveAmount: PropTypes.func,
	onClickRemoveRow: PropTypes.func,
	disableRemoveAmount: PropTypes.bool,
	onClose: PropTypes.func,
	title: PropTypes.string
}

export default SelectRowCorrection
