import React from 'react'
import PropTypes from 'prop-types'
import './style.css'

const InboundInfo = ({ item }) => {
	return (
		<div className="inboundInfo">
			<div className="inbound header">
				<span>Sequencenumber</span>
				<span>Delivery note number</span>
			</div>
			<div className="inbound">
				<span>{item.sequenceNumber}</span>
				<span>{item.wayBillNumber}</span>
			</div>
		</div>
	)
}

InboundInfo.propTypes = {
	item: PropTypes.object.isRequired,
}

export default InboundInfo
