import './style.css'

import React, { useState } from 'react'
import propTypes from 'prop-types'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import FloatingNumpad from 'components/FloatingNumpad'

const InboundDeliverySearchForm = ({
	onSubmit,
	onBack,
	onChange,
	suppliers,
	supplier,
	ean,
	supplierArticleNumber,
	waybillNumber,
	inboundDeliveryType,
	supplierOrderNumber
}) => {
	const [editing, setEditing] = useState(null)
	const [currentValue, setCurrentValue] = useState('')
	const hideWaybillInput = inboundDeliveryType === 'purchaseOrder' ? 'd-none' : ''
	const hideSupplierOrderInput = inboundDeliveryType === 'customerReturn' ? 'd-none' : ''

	const onEditHandler = (name) => {
		setCurrentValue(name.target.value)
		setEditing(name.target.name)
	}

	const onChangeHandler = (name, value) => {
		setCurrentValue(value)
		onChange(name, value)
	}

	const onWayBillChange = (e) => {
		onChange('waybillNumber', e.target.value)
	}

	const onSupplierOrderNumberChange = (e) => {
		onChange('supplierOrderNumber', e.target.value)
	}

	const onNumpadChange = (value) => {
		onChangeHandler(editing, value)
	}

	const onConfirmNumpad = (name, value) => {
		onChange(name, value)
		setEditing(null)
	}

	const onClearNumpad = () => {
		onChangeHandler(editing, '')
	}

	const onChangeSupplier = (e) => {
		onChange('supplier', e.target.value)
	}

	const onChangeInboundDeliveryType = (e) => {
		switch (e.target.value) {
			case 'purchaseOrder':
				onChangeHandler('waybillNumber', '')
				break
			case 'customerReturn':
				onChangeHandler('supplierOrderNumber', '')
				break
			default:
				break
		}

		onChangeHandler('inboundDeliveryType', e.target.value)
	}

	return (
		<Container className="inboundDeliverySearchForm">
			<Row className="justify-content-center">
				<Col xs={12} sm={12}>

					<Form.Group>
						<ButtonGroup size="lg">
							<Button
								variant={inboundDeliveryType === 'delivery' ? 'warning' : 'outline-warning'}
								type="button"
								value="delivery"
								onClick={onChangeInboundDeliveryType}>
								All deliveries
							</Button>
						</ButtonGroup>
					</Form.Group>

					<Form.Group>
						<ButtonGroup size="lg">
							<Button
								variant={inboundDeliveryType === 'purchaseOrder' ? 'warning' : 'outline-warning'}
								type="button"
								value="purchaseOrder"
								onClick={onChangeInboundDeliveryType}>
								Purchase orders
							</Button>
						</ButtonGroup>
					</Form.Group>

					<Form.Group>
						<ButtonGroup size="lg">
							<Button
								variant={inboundDeliveryType === 'customerReturn' ? 'warning' : 'outline-warning'}
								type="button"
								value="customerReturn"
								onClick={onChangeInboundDeliveryType}>
								Customer Returns
							</Button>
						</ButtonGroup>
					</Form.Group>

					<Card>
						<Card.Header>Inbound Delivery Search</Card.Header>
						<Card.Body>
							<Form onSubmit={onSubmit}>
								<Form.Group controlId="Supplier">
									<Form.Control
										disabled={!suppliers}
										name="supplier"
										onChange={onChangeSupplier}
										value={supplier}
										as="select"
										size="lg"
									>
										<option value="">- Select supplier -</option>
										{suppliers
											? suppliers.map((item) => (
												<option key={item.id} value={item.id}>
													{item.name}
												</option>
											))
											: ''}
									</Form.Control>
								</Form.Group>

								<Form.Group controlId="EAN">
									<Form.Control
										name="ean"
										type="number"
										size="xxl"
										placeholder="EAN"
										className="editable"
										onClick={onEditHandler}
										onChange={onChangeHandler}
										readOnly
										value={ean}
									/>
								</Form.Group>

								<Form.Group controlId="SupplierArticleNumber">
									<Form.Control
										name="supplierArticleNumber"
										type="text"
										size="xxl"
										placeholder="Supplier Article Number"
										className="editable"
										onClick={onEditHandler}
										onChange={onChangeHandler}
										readOnly
										value={supplierArticleNumber}
									/>
								</Form.Group>

								<Form.Group controlId="WaybillNumber">
									<Form.Control
										name="waybillNumber"
										type="text"
										size="xxl"
										placeholder="Delivery note number"
										className={hideWaybillInput + ' form-control'}
										value={waybillNumber}
										onChange={onWayBillChange}
									/>
								</Form.Group>

								<Form.Group controlId="SupplierOrderNumber">
									<Form.Control
										name="supplierOrderNumber"
										type="text"
										size="xxl"
										placeholder="Supplier order number"
										className={hideSupplierOrderInput + ' form-control'}
										value={supplierOrderNumber}
										onChange={onSupplierOrderNumberChange}
									/>
								</Form.Group>
							</Form>
						</Card.Body>
					</Card>

					<Form.Group>
						<ButtonGroup size="lg">
							<Button variant="primary" type="submit" onClick={onSubmit}>
								Submit
							</Button>
						</ButtonGroup>
					</Form.Group>
				</Col>
			</Row>

			{editing ? (
				<FloatingNumpad
					onChange={onNumpadChange}
					text={currentValue}
					onSend={onConfirmNumpad}
					onClear={onClearNumpad}
					showStockButton={false}
					hideExtraButton={false}
				/>
			) : (
				''
			)}
		</Container>
	)
}

InboundDeliverySearchForm.propTypes = {
	onSubmit: propTypes.func,
	onBack: propTypes.func,
	supplier: propTypes.string,
	suppliers: propTypes.array,
	ean: propTypes.string,
	supplierArticleNumber: propTypes.string,
	waybillNumber: propTypes.string,
	onChange: propTypes.func,
	inboundDeliveryType: propTypes.string,
	supplierOrderNumber: propTypes.string
}

export default InboundDeliverySearchForm
