import React from 'react'
import PropTypes from 'prop-types'
import Form from 'react-bootstrap/Form'
import { useGetLoaderTyreDimensionsQuery } from 'api/loaderDimensionsApi'
import './style.css'

const LoaderDimension = ({ selectedItem, onChange, disabled }) => {

	const { data: items, isFetching: loading } = useGetLoaderTyreDimensionsQuery()

	return (
		<div className="loaderDimension">
			<Form.Group>
				<Form.Control
					name="loaderDimension"
					onChange={onChange}
					value={selectedItem}
					disabled={loading || disabled}
					as="select"
					size="lg"
				>
					<option value="">- Select dimension -</option>
					{items?.map((item) => (
						<option key={item.key} value={item.key}>
							{item.value}
						</option>
					))}
				</Form.Control>
			</Form.Group>
		</div>
	)
}

LoaderDimension.propTypes = {
	selectedItem: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
}

export default LoaderDimension
