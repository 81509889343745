import './style.css'
import React, { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'
import FloatingNumpad from 'components/FloatingNumpad'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FloatingWindow from 'components/FloatingWindow'
import PropTypes from 'prop-types'
import LoadingSpinner from 'components/LoadingSpinner'
import ConfirmationWindow from 'components/ConfirmationWindow/ConfirmationWindow'
import InformationBox from 'components/InformationBox/InformationBox'
import If from 'components/If'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { showMessage } from '../MessageBox/duck'
import { usePerformStockLocationMoveMutation } from 'api/stockLocationBulkMove'

const StockLocationMove = ({
	currentStockLocation,
	articles,
}) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const [criteria, setCriteria] = useState({
		editing: null,
		currentStockLocation: currentStockLocation,
		newStockLocation: '',
		warning: false
	})

	const [performStockLocationMove, { isLoading: loading }] = usePerformStockLocationMoveMutation()

	const containsQuantityReserved = articles.filter(a => a.quantityReserved > 0)

	let warningMessage = 'Are you sure you want to move the stock location from ' + currentStockLocation + ' to ' + criteria.newStockLocation + '?'

	const handleOnSubmit = () => {
		if (criteria.newStockLocation !== '' && criteria.newStockLocation !== criteria.currentStockLocation) {
			setCriteria(prevCriteria => ({
				...prevCriteria,
				warning: true
			}))
		} else {
			dispatch(showMessage('Warning', 'Please enter a new stock location'))
		}
	}

	const handleConfirmMove = async () => {
		try {
			await performStockLocationMove({
				fromLocation: criteria.currentStockLocation,
				toLocation: criteria.newStockLocation,
			}).unwrap()

			dispatch(showMessage('Success', 'Stock location moved successfully'))
			navigate(-1)
			setCriteria((prev) => ({ ...prev, warning: false }))
		} catch (err) {
			dispatch(showMessage('Error', 'Failed to move stock location'))
		}
	}

	const handleCancelMove = () => {
		setCriteria((prev) => ({ ...prev, warning: false }))
	}

	const onEditHandler = (name) => {
		setCriteria(prevCriteria => ({
			...prevCriteria,
			editing: name.target.name
		}))
	}

	const onChangeHandler = (value) => {
		setCriteria(prevCriteria => ({
			...prevCriteria,
			newStockLocation: value
		}))
	}

	const onConfirmNumpad = () => {
		setCriteria(prevCriteria => ({
			...prevCriteria,
			editing: null
		}))
	}

	const onClearNumpad = () => {
		onChangeHandler('')
	}

	const onBack = () => {
		navigate(-1)
	}

	return (
		<Container className="StockLocationMove">
			<FloatingWindow className="justify-content-center" closeOutSide={false} draggable={false} showHeader={true} fullSize={true} title='Move Stock'>
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<div>From Location</div>
						<Form.Group controlId="currentStockLocation">
							<Form.Control
								name="currentStockLocation"
								type="string"
								size="xxl"
								placeholder="Current Stock Location"
								readOnly
								value={criteria.currentStockLocation}
							/>
						</Form.Group>

						<div>To Location</div>
						<Form.Group controlId="newStockLocation">
							<Form.Control
								name="newStockLocation"
								type="string"
								size="xxl"
								placeholder="New Stock Location"
								className="editable"
								onClick={onEditHandler}
								onChange={onChangeHandler}
								readOnly
								value={criteria.newStockLocation}
							/>
						</Form.Group>

						<If condition={containsQuantityReserved?.length > 0}>
							<InformationBox message={'Unable to move, stock location contains reserved quantities'} />
						</If>


						<Form.Group>
							<ButtonGroup size="lg">
								<Button variant="warning" size="lg" onClick={onBack}>
									Cancel
								</Button>
								<Button variant="primary" type="button" onClick={handleOnSubmit} disabled={containsQuantityReserved?.length > 0}>
									Submit
								</Button>
							</ButtonGroup>
						</Form.Group>
					</Col>
				</Row>
				{loading ? <LoadingSpinner /> : ''}
			</FloatingWindow>

			{criteria.editing ? (
				<FloatingNumpad
					onChange={onChangeHandler}
					text={criteria.newStockLocation}
					onSend={onConfirmNumpad}
					onClear={onClearNumpad}
					showStockButton={true}
					hideExtraButton={false}
				/>
			) : (
				''
			)}

			{criteria.warning && (
				<ConfirmationWindow
					title="warning"
					message={warningMessage}
					disabled={loading}
					confirmLabel={'Yes'}
					cancelLabel={'No'}
					onCancel={handleCancelMove}
					onConfirm={handleConfirmMove}
				/>
			)}
		</Container>
	)
}

StockLocationMove.propTypes = {
	currentStockLocation: PropTypes.string,
	articles: PropTypes.array
}

export default StockLocationMove