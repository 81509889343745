import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'

import './style.css'
import NumpadInput from 'components/NumpadInput/NumpadInput'

const InboundDeliveryRow = ({ row, disabled, onChange, deleteRow }) => {
	const handleOnChange = (name, value) => {

		onChange(name, value, row.id)
	}

	const onHandleDeleteRow = () => {
		deleteRow(row.id)
	}

	return (
		<div className="inboundDeliveryRow">
			<span className="articleText">{row.articleText}</span>
			<div className="deliveryRow header">
				<span>{'Quantity ordered'}</span>
				<span>{'Quantity expected'}</span>
			</div>
			<div className="deliveryRow">
				<span>{row.quantityOrdered}</span>
				<span>{row.quantityExpected}</span>
			</div>
			<div className="deliveryRow header">
				<span>{'Article id'}</span>
			</div>
			<div className="deliveryRow">
				<span>{row.articleId}</span>
			</div>
			<div className="btnLabels row">
				<span>{'Quantity delivered'}</span>
				<span>{'Stock location'}</span>
			</div>
			<div className="inputs">
				<div className="input">
					<NumpadInput
						name="quantityDelivered"
						disabled={disabled}
						value={row.quantityDelivered}
						onChange={handleOnChange}
					></NumpadInput>
				</div>
				<div className="input">
					<NumpadInput
						name="stockLocation"
						disabled={disabled}
						value={row.stockLocation}
						onChange={handleOnChange}
					></NumpadInput>
				</div>
			</div>
			<div className="btnLabels row">
				<span>{'Dot year'}</span>
				<span>{'Weight'}</span>
			</div>
			<div className="inputs">
				<div className="input">
					<NumpadInput
						name="dotYear"
						disabled={disabled}
						value={row.dotYear || ''}
						onChange={handleOnChange}
					></NumpadInput>
				</div>
				<div className="input">
					<NumpadInput
						name="weight"
						disabled={disabled}
						value={row.weight || ''}
						onChange={handleOnChange}
					></NumpadInput>
				</div>
			</div>

			<div className="deleteInboundRow row">
				<Button
					className='deleteInboundRowButton'
					variant="danger"
					size="xxl"
					onClick={onHandleDeleteRow}>
					Delete row
				</Button>
			</div>

			{row.brokenRules.map((i) => (
				<div key={i.description} className="broken-rule">
					<span>{i.property} : </span>
					<span>{i.description}</span>
				</div>
			))}
		</div>
	)
}

InboundDeliveryRow.propTypes = {
	row: PropTypes.object,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	deleteRow: PropTypes.func,
}

export default InboundDeliveryRow
