import { apiBase } from './apiBase'
import { getQueryString } from 'lib/utils'

export const stockLocationApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getStockLocation: builder.query({
			query: (criteria) => `stocklocation?${getQueryString(criteria)}`,
		}),
		validateStockLocation: builder.query({
			query: (criteria) => `stocklocationCompare?${getQueryString(criteria)}`,
		}),
	}),
})

export const {
	useGetStockLocationQuery,
	useLazyGetStockLocationQuery,
	useValidateStockLocationQuery,
	useLazyValidateStockLocationQuery,
} = stockLocationApi