import React from 'react'
import Button from 'react-bootstrap/Button'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const ArticleTypeButton = (props) => {
	const onClick = () => {
		props.onClick(props.articleType)
	}

	return (
		<Button
			variant="outline-dark"
			className={classNames({ active: props.active === props.articleType })}
			onClick={onClick}
		>
			{props.text}
		</Button>
	)
}

ArticleTypeButton.propTypes = {
	active: PropTypes.number,
	onClick: PropTypes.func.isRequired,
	text: PropTypes.string,
}

export default ArticleTypeButton
