import { apiBase } from './apiBase'


export const appApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		fetchLanguage: builder.query({
			query: (languageCode) => ({
				url: 'language/',
				method: 'GET',
				params: { languageCode },
			}),
		}),
		fetchSettings: builder.query({
			query: () => ({
				url: 'settings/',
				method: 'GET',
			}),
		}),
	}),
})

export const { 
	useFetchLanguageQuery, 
	useFetchSettingsQuery, 
	useLazyFetchSettingsQuery 
} = appApi
