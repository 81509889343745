const articleType = {
	all: 0,
	tyre: 1, //PCR (PV)
	truckTyre: 2, //TBR (LV/BUS)
	loadTyre: 3, //OTR (EM/TRAKTOR)
	rim: 4, //PCW (PV)
	truckRims: 5, //TBW/OTW (LV/BUS)
	MOTO: 6, // MOTO
}
export default articleType
