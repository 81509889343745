import { useState, useEffect } from 'react'
import { useCreateNewStockTakingMutation } from 'api/stockTakingApi'
import { useNavigate } from 'react-router-dom'
import { usePrevious } from 'lib/utils'

const useStockTakingCreator = () => {
	const navigate = useNavigate()
	const [createdSequenceNumber, setCreatedSequenceNumber] = useState(null)
	const prevSequenceNumber = usePrevious(createdSequenceNumber)

	const [triggerCreateNewStockTaking, { isLoading: loadingStockTaking }] = useCreateNewStockTakingMutation()

	const createStockTakingByLocations = async (stockLocations) => {
		try {
			const response = await triggerCreateNewStockTaking({
				stockLocations,
			}).unwrap()
			setCreatedSequenceNumber(response)
		} catch (error) {
			console.error('Error creating stock taking by locations:', error)
		}
	}

	const createStockTakingByArticleIds = async (articleIds, stockLocation) => {
		try {
			const response = await triggerCreateNewStockTaking({
				articleIds,
				stockLocation,
			}).unwrap()
			setCreatedSequenceNumber(response)
		} catch (error) {
			console.error('Error creating stock taking by article IDs:', error)
		}
	}

	useEffect(() => {
		if (prevSequenceNumber === null && createdSequenceNumber !== null) {
			navigate(`/stocktaking/${createdSequenceNumber}`)
		}
	}, [prevSequenceNumber, createdSequenceNumber, navigate])

	return {
		loadingStockTaking,
		createdSequenceNumber,
		createStockTakingByLocations,
		createStockTakingByArticleIds,
	}
}

export default useStockTakingCreator
