// redux/slices/appSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { appApi } from 'api/appApi'

const initialState = {
	settings: {},
	language: {},
	currentLanguage: 'sv',
	mqttClient: null,
}

const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setConnectionMQClient(state, action) {
			state.mqttClient = action.payload
		},
	},
	extraReducers: (builder) => {
		builder
			.addMatcher(
				appApi.endpoints.fetchLanguage.matchPending,
				(state) => {
					state.loading = true
					state.error = null
				}
			)
			.addMatcher(
				appApi.endpoints.fetchLanguage.matchFulfilled,
				(state, action) => {
					state.loading = false
					state.language = action.payload
				}
			)
			.addMatcher(
				appApi.endpoints.fetchLanguage.matchRejected,
				(state, action) => {
					state.loading = false
					state.error = action.error
				}
			)
			.addMatcher(
				appApi.endpoints.fetchSettings.matchPending,
				(state) => {
					state.loading = true
					state.error = null
				}
			)
			.addMatcher(
				appApi.endpoints.fetchSettings.matchFulfilled,
				(state, action) => {
					state.loading = false
					state.settings = action.payload
				}
			)
			.addMatcher(
				appApi.endpoints.fetchSettings.matchRejected,
				(state, action) => {
					state.loading = false
					state.error = action.error
				}
			)
	},
})

export const { setConnectionMQClient } = appSlice.actions

export default appSlice.reducer
