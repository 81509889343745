// redux/slices/loginSlice.js
import { createSlice } from '@reduxjs/toolkit'
import { loginApi } from 'api/loginApi'

const initialState = {
	user: null,
	loggedIn: false,
	invalidLogin: false,
	initialCheck: false,
	loading: false,
	error: null,
}

const loginSlice = createSlice({
	name: 'login',
	initialState,
	reducers: {
		resetError(state) {
			state.error = null
			state.invalidLogin = false
		},
	},
	extraReducers: (builder) => {
		// Handle login
		builder
			.addMatcher(loginApi.endpoints.sendLogin.matchPending, (state) => {
				state.loading = true
				state.error = null
			})
			.addMatcher(loginApi.endpoints.sendLogin.matchFulfilled, (state, action) => {
				state.loading = false
				state.loggedIn = true
				state.user = action.payload.user
			})
			.addMatcher(loginApi.endpoints.sendLogin.matchRejected, (state, action) => {
				state.loading = false
				state.invalidLogin = true
				state.error = action.error
			})
			// Handle checkLogin
			.addMatcher(loginApi.endpoints.checkLogin.matchPending, (state) => {
				state.loading = true
			})
			.addMatcher(loginApi.endpoints.checkLogin.matchFulfilled, (state, action) => {
				state.loading = false
				state.loggedIn = true
				state.initialCheck = true
			})
			.addMatcher(loginApi.endpoints.checkLogin.matchRejected, (state) => {
				state.loading = false
				state.loggedIn = false
				state.initialCheck = true
			})
			// Handle logout
			.addMatcher(loginApi.endpoints.logout.matchFulfilled, (state) => {
				state.loggedIn = false
				state.user = null
			})
			// Handle fetchUser
			.addMatcher(loginApi.endpoints.fetchUser.matchFulfilled, (state, action) => {
				state.user = action.payload
			})
	},
})

export const { resetError } = loginSlice.actions
export default loginSlice.reducer
