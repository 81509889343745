import React, { useEffect } from 'react'
import { showMessage } from 'features/MessageBox/duck'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Button, Card } from 'react-bootstrap'
import LoadingSpinner from 'components/LoadingSpinner'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import ConcurrencyNotification from 'components/ConcurrencyNotification'
import purchaseOrderDeliveryStatus, { getPurchaseOrderDeliveryStatus } from 'enums/purchaseOrderDeliveryStatus'
import InboundDeliveryRow from 'components/InboundDeliveryRow'
import PropTypes from 'prop-types'
import {
	useFetchPurchaseOrderDeliveryQuery,
	useSavePurchaseOrderDeliveryMutation,
	useCompleteOrderDeliveryMutation,
	useDeleteInboundOrderRowMutation,
} from '../../api/inboundOrderApi'

const InboundOrder = () => {
	const { sequenceNumber } = useParams()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const { data: inboundOrder, error: fetchError, isLoading, refetch } = useFetchPurchaseOrderDeliveryQuery(sequenceNumber)
	const [savePurchaseOrderDelivery, { error: saveError }] = useSavePurchaseOrderDeliveryMutation()
	const [completeOrderDelivery, { error: completeError }] = useCompleteOrderDeliveryMutation()
	const [deleteInboundOrderRow, { error: deleteError }] = useDeleteInboundOrderRowMutation()

	const [localInboundOrder, setLocalInboundOrder] = React.useState(inboundOrder)
	const [concurrencyError, setConcurrencyError] = React.useState(null)


	const handleSave = async () => {
		if (localInboundOrder) {
			const payload = {
				sequenceNumber: localInboundOrder.sequenceNumber,
				lastChanged: localInboundOrder.lastChanged,
				rows: localInboundOrder.rows.map(row => ({
					Id: row.id,
					QuantityDelivered: row.quantityDelivered,
					StockLocation: row.stockLocation,
					DotYear: row.dotYear,
					Weight: row.weight,
				})),
			}
			try {
				await savePurchaseOrderDelivery(payload).unwrap()
				refetch()
			} catch (error) {
				if (error.status === 409) {
					setConcurrencyError('409')
				}
			}
		}
	}

	const handleComplete = async () => {
		if (localInboundOrder) {
			const payload = {
				sequenceNumber: localInboundOrder.sequenceNumber,
				lastChanged: localInboundOrder.lastChanged,
			}
			try {
				await completeOrderDelivery(payload).unwrap()
				refetch()
			} catch (error) {
				if (error.status === 409) {
					setConcurrencyError(true)
				}
			}
		}
	}

	const handleDeleteRow = async (rowId) => {
		try {
			await deleteInboundOrderRow({ sequenceNumber, rowId }).unwrap()
			refetch()
		} catch (error) {
			if (error.status === 409) {
				setConcurrencyError(true)
			}
		}
	}

	const handleGoBack = () => navigate(-1)
	const handleGoHome = () => navigate('/')

	const handleCloseConcurrencyNotification = () => {
		setConcurrencyError(null)
	}

	useEffect(() => {
		setLocalInboundOrder(inboundOrder)
	}, [inboundOrder])

	useEffect(() => {
		const getErrorMessage = (error) => {
			return error?.data?.message || error?.message || 'An unknown error occurred'
		}

		if (saveError) {
			dispatch(showMessage('Error', getErrorMessage(saveError)))
		}
		if (completeError) {
			dispatch(showMessage('Error', getErrorMessage(completeError)))
		}
		if (deleteError) {
			dispatch(showMessage('Error', getErrorMessage(deleteError)))
		}
	}, [saveError, completeError, deleteError, dispatch])

	if (isLoading) return <LoadingSpinner />
	if (fetchError) return <div>Error loading inbound order</div>

	return (
		<>
			<Container className="inboundOrder">
				{localInboundOrder && (
					<Row className="justify-content-center">
						<Col xs={12} sm={12}>
							<Row className="justify-content-center">
								<Card>
									<Card.Body>
										<div className="item row">
											{'Sequencenumber:'}
											<div className="info">{localInboundOrder.sequenceNumber}</div>
										</div>
										<div className="item row">
											{'Waybillnumber:'}
											<div className="info">{localInboundOrder.waybillNumber}</div>
										</div>
										<div className="item row">
											{'Suppliername:'}
											<div className="info">{localInboundOrder.supplierName}</div>
										</div>
										<div className="item row">
											{'Status:'}
											<div className="info">
												{getPurchaseOrderDeliveryStatus(localInboundOrder.status)}
											</div>
										</div>
										{
											localInboundOrder.status ===
											purchaseOrderDeliveryStatus.ready &&
											(
												<Button
													variant="success"
													size="sm"
													onClick={handleComplete}
												>
													Set as delivered
												</Button>
											)}
										{localInboundOrder.rows?.map((item) => (
											<div key={item.id} className="row-card">
												<InboundDeliveryRow
													row={item}
													disabled={localInboundOrder.status === purchaseOrderDeliveryStatus.delivered}
													onChange={(name, value) => {
														const updatedRows = localInboundOrder.rows.map(row => {
															if (row.id === item.id) {
																return { ...row, [name]: value }
															}
															return row
														})
														setLocalInboundOrder(prev => ({
															...prev,
															rows: updatedRows
														}))
													}}
													deleteRow={() => handleDeleteRow(item.id)}
												/>
											</div>
										))}
									</Card.Body>
								</Card>
							</Row>
						</Col>
					</Row>
				)}
				{concurrencyError === '409' && (
					<ConcurrencyNotification onClose={handleCloseConcurrencyNotification} />
				)}

			</Container>
			<FloatingButtonWrapper>
				<Button variant="success" size="lg" onClick={handleSave}>Save</Button>
				<Button variant="info" size="lg" onClick={handleGoBack}>Back</Button>
				<Button variant="danger" size="lg" onClick={handleGoHome}>Home</Button>
			</FloatingButtonWrapper>
		</>
	)
}

InboundOrder.propTypes = {
	match: PropTypes.object,
}

export default InboundOrder