import React, { useRef, useEffect, useState } from 'react'
import { Html5Qrcode } from 'html5-qrcode'
import './style.css'
import PropTypes from 'prop-types'

const qrcodeRegionId = 'html5qr-code-full-region'

const Html5QrcodePlugin = ({
	fps,
	qrbox,
	aspectRatio,
	disableFlip,
	qrCodeSuccessCallback,
	qrCodeErrorCallback,
	verbose,
}) => {
	const html5Qrcode = useRef(null)
	const qrcodeRegion = useRef(null)
	const [scannerStarted, setScannerStarted] = useState(false)

	useEffect(() => {
		const startScanner = async () => {
			const config = {
				fps,
				qrbox,
				aspectRatio,
				disableFlip,
			}

			html5Qrcode.current = new Html5Qrcode(qrcodeRegionId, verbose)
			try {
				await html5Qrcode.current.start(
					{ facingMode: 'environment' },
					config,
					(text) => {
						if (qrCodeSuccessCallback) {
							qrCodeSuccessCallback(text)
						}
					},
					qrCodeErrorCallback
				)
				setScannerStarted(true)
			} catch (error) {
				console.error('Failed to start Html5Qrcode. ', error)
			}

			qrcodeRegion.current.style.width = qrbox + '%'
			qrcodeRegion.current.style.height = qrbox + '%'
		}

		if (!scannerStarted && !html5Qrcode.current) {
			startScanner()
		}

		return () => {
			if (html5Qrcode.current && scannerStarted) {
				html5Qrcode.current.stop().then(() => {
					setScannerStarted(false)
				})
			}
		}
	}, [aspectRatio, disableFlip, fps, qrCodeErrorCallback, qrCodeSuccessCallback, qrbox, scannerStarted, verbose])

	return (
		<div className="code-reader">
			<div ref={qrcodeRegion} id={qrcodeRegionId} />
		</div>
	)
}

Html5QrcodePlugin.propTypes = {
	fps: PropTypes.number,
	qrbox: PropTypes.func,
	aspectRatio: PropTypes.number,
	disableFlip: PropTypes.bool,
	qrCodeSuccessCallback: PropTypes.func,
	qrCodeErrorCallback: PropTypes.func,
	verbose: PropTypes.bool,
}

export default Html5QrcodePlugin