/**
 * fetch middleare for redux
 * Usage:
 * In redux action object create types and callApi property.
 * {
 *    types: [FETCH, SUCCESS, FAILED],
 *    callApi: {
 *      endpoint: 'settings',
 *      method: 'GET',
 *      data: Object (optional)
 *      hostname: htp:/... (optional),
 *      credentials: 'include' (optional),
 *      processData: function (optional),
 *      meta: Object (optional)
 *    }
 * }
 */

const queryCreator = (object) => {
	var string = Object.keys(object)
		.map(function (k) {
			if (Array.isArray(object[k])) {
				var arr = []

				object[k].forEach(function (item) {
					arr.push(k + '[]=' + item)
				})

				return arr.join('&')
			}

			return encodeURIComponent(k) + '=' + encodeURIComponent(object[k])
		})
		.join('&')

	if (string.length > 0) string = '?' + string

	return string
}

const fetchMiddleware = ({ dispatch }) =>
	(next) =>
		(action) => {
			const { types, callApi, ...rest } = action

			if (!types || !callApi) {
				return next(action)
			}

			if (
				!Array.isArray(types) ||
				types.length !== 3 ||
				!types.every((type) => typeof type === 'string')
			) {
				throw new Error('Expected an array of three string types.')
			}

			const [requestType, successType, failureType] = types

			dispatch({
				...rest,
				type: requestType,
			})

			let apiUrl = callApi.hostname || process.env.REACT_APP_API_URL

			const headers = {
				'content-type': 'application/json',
			}

			const options = {
				headers: headers,
				method: callApi.method || 'GET',
				credentials: callApi.credentials || 'include',
			}

			let endpoint = `${apiUrl}/${callApi.endpoint}`

			//Process data if any
			if (callApi.data) {
				if (options.method === 'GET' || options.method === 'DELETE') {
					endpoint = endpoint + queryCreator(callApi.data)
				} else {
					options.body = JSON.stringify(callApi.data)
				}
			}

			return fetch(endpoint, options)
				.then((response) => {
					if (!response.ok) {
						throw new Error(response.status)
					}

					const contentType = response.headers.get('content-type')
					if (contentType && contentType.indexOf('application/json') !== -1) {
						return response.json()
					} else {
						return response
					}
				})
				.then((data) => {
					if (callApi.processData) data = callApi.processData(data)

					dispatch({
						...rest,
						type: successType,
						data,
					})
				})
				.catch((error) => {
					dispatch({
						type: failureType,
						message: error.message,
						error,
					})
				})
		}
export default fetchMiddleware
