import { apiBase } from './apiBase'

export const pickingApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		fetchPickingList: builder.query({
			query: (payload) => ({
				url: 'PickingList',
				method: 'GET',
				params: payload,
			}),
		}),
		startNewPickingList: builder.mutation({
			query: (payload) => ({
				url: 'PickingList',
				method: 'POST',
				body: payload,
			}),
		}),
		completePickingList: builder.mutation({
			query: (payload) => ({
				url: 'PickingList',
				method: 'PUT',
				body: payload,
			}),
		}),
		performPickingListRowCorrection: builder.mutation({
			query: (payload) => ({
				url: 'PickingRow',
				method: 'PUT',
				body: payload,
			}),
		}),
		performPickingListRowDeleteCorrection: builder.mutation({
			query: ({ ArticleId, StockLocation, NextBusinessDay }) => ({
				url: 'PickingRow',
				method: 'DELETE',
				body: { ArticleId, StockLocation, NextBusinessDay },
			}),
		}),
		performPickingListRowPartialCorrection: builder.mutation({
			query: (payload) => ({
				url: 'pickingRowPartiallyPicked',
				method: 'PUT',
				body: payload,
			}),
		}),
		fetchPickingFilter: builder.query({
			query: () => ({
				url: 'PickingFilter',
				method: 'GET',
			}),
		}),
		bundleItems: builder.mutation({
			query: (payload) => ({
				url: 'BundlePicking',
				method: 'POST',
				body: payload,
			}),
		}),
	}),
})

export const {
	useFetchPickingListQuery,
	useLazyFetchPickingListQuery,
	useStartNewPickingListMutation,
	useCompletePickingListMutation,
	usePerformPickingListRowCorrectionMutation,
	usePerformPickingListRowDeleteCorrectionMutation,
	usePerformPickingListRowPartialCorrectionMutation,
	useFetchPickingFilterQuery,
	useLazyFetchPickingFilterQuery,
	useBundleItemsMutation,
} = pickingApi