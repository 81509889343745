import React from 'react'

const NumpadButton = ({ value, disabled, onClick }) => {
	const handleClick = (e) => {
		e.preventDefault()
		onClick(value)
	}

	return (
		<button onClick={handleClick} disabled={disabled || false}>
			{value}
		</button>
	)
}

export default NumpadButton
