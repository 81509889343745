import React from 'react'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import PropTypes from 'prop-types'
import articleType from 'enums/articleType'

const PickingListCreateForm = ({
	pickingGroups,
	selectedGroup,
	pickingBuildings,
	selectedBuilding,
	selectedFloor,
	selectedAccessoryOption,
	selectedProductGroup,
	onChangePickingGroup,
	onChangePickingBuilding,
	onChangePickingFloor,
	onChangePickingAccessory,
	onChangePickingProductGroup
}) => {
	return (
		<Row className="justify-content-center">
			<Form.Control
				disabled={!pickingGroups}
				name="pickingGroup"
				className="pickingListSelect"
				onChange={onChangePickingGroup}
				value={selectedGroup}
				as="select"
				size="lg"
			>
				<option value="0">- Select picking group -</option>
				{pickingGroups
					? pickingGroups.map((item) => (
						<option key={item.id} value={item.id}>
							{item.displayText}
						</option>
					))
					: ''}
			</Form.Control>
			<Form.Control
				disabled={!pickingGroups}
				name="pickingBuilding"
				className="pickingListSelect"
				onChange={onChangePickingBuilding}
				value={selectedBuilding}
				as="select"
				size="lg"
			>
				<option value="0">- All buildings -</option>
				{pickingBuildings
					? pickingBuildings.map((item) => (
						<option key={item} value={item}>
							Building No. {item}
						</option>
					))
					: ''}
			</Form.Control>
			<Form.Control
				disabled={!pickingGroups}
				name="pickingFloor"
				className="pickingListSelect"
				onChange={onChangePickingFloor}
				value={selectedFloor}
				as="select"
				size="lg"
			>
				<option value="0">- All floors -</option>
				<option value="1"> 1st Floor </option>
				<option value="2"> Exclude 1st Floor </option>
			</Form.Control>
			<Form.Control
				disabled={!pickingGroups}
				name="pickingAccesory"
				className="pickingListSelect"
				onChange={onChangePickingAccessory}
				value={selectedAccessoryOption}
				as="select"
				size="lg"
			>
				<option value="0">- Select option -</option>
				<option value="1"> Exclude accessories </option>
			</Form.Control>
			<Form.Control
				disabled={!pickingGroups}
				name="pickingProductGroup"
				className="pickingListSelect"
				onChange={onChangePickingProductGroup}
				value={selectedProductGroup}
				as="select"
				size="lg"
			>
				<option value="0">- All product group -</option>
				<option value={articleType.tyre}> PCR </option>
				<option value={articleType.truckTyre}> TBR </option>
				<option value={articleType.loadTyre}> OTR </option>
				<option value={articleType.rim}> PCW </option>
				<option value={articleType.truckRims}> TBW/OTW </option>
				<option value={articleType.MOTO}> MOTO </option>

			</Form.Control>
		</Row>
	)
}

PickingListCreateForm.propTypes = {
	pickingGroups: PropTypes.array,
	selectedGroup: PropTypes.string,
	selectedBuilding: PropTypes.string,
	selectedFloor: PropTypes.string,
	selectedAccessoryOption: PropTypes.string,
	selectedProductGroup: PropTypes.string,
	onChangePickingGroup: PropTypes.func,
	onChangePickingFloor: PropTypes.func,
	onChangePickingAccessory: PropTypes.func,
	onChangePickingProductGroup: PropTypes.func,
	onChangePickingBuilding: PropTypes.func,
	pickingBuildings: PropTypes.array,
}

export default PickingListCreateForm