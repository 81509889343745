import { apiBase } from './apiBase'

export const stockArticlesApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getStockLocationArticles: builder.query({
			query: (stockLocation) => `stockLocationSearch/${stockLocation}`,
		}),
	}),
})

export const {
	useGetStockLocationArticlesQuery,
	useLazyGetStockLocationArticlesQuery
} = stockArticlesApi