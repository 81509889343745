import React from 'react'
import Card from 'react-bootstrap/Card'
import PropTypes from 'prop-types'
import { format } from 'lib/utils'

import './style.css'

const ArticleTitle = ({ item, dotYear }) => {
	const dotYearValue = item.dotYear || dotYear

	let articleText = ''
	if (item.mainGroupId === 220 || item.mainGroupId === 210) {
		articleText = `${format(item.width)}x${item.diameter} ${item.numberOfBolts}/${item.boltCircle} ET${item.offset}`
	} else if (item.mainGroupId === 80) {
		const plyRating = item.plyRating ? ` ${item.plyRating}PR` : ''
		let size
		if (item.aspectRatio && item.aspectRatio > 0)
			size = `${item.width}/${item.aspectRatio}-${item.diameter}`
		else size = `${item.width}-${item.diameter}`

		articleText = `${size}${plyRating} ${item.extra}`
	} else if (item.mainGroupId < 100) {
		const dotYearText = `${dotYearValue ? '-' + (dotYearValue - 2000) : ''}`
		articleText = `${item.width}/${item.aspectRatio}R${item.diameter} ${item.loadIndex}${item.speedIndexCode} ${dotYearText}`
	}
	if (item.oeMarking) {
		articleText = `${articleText} ${item.oeMarking}`
	} else articleText = item.articleText

	let studdedText = ''
	if (item.studded) {
		studdedText = 'Studded'
	} else if (item.studdable) {
		studdedText = 'Studdable'
	}

	return (
		<div className="articleTitle">
			<Card.Title style={{ textAlign: 'left' }}>{articleText}</Card.Title>
			<Card.Subtitle
				style={{ textAlign: 'left' }}
				className="text-muted"
			>{`${item.brand} ${item.pattern} ${studdedText}`}</Card.Subtitle>
			{item.brand !== item.subGroup ? (
				<div className="subGroup">{item.subGroup}</div>
			) : (
				''
			)}
		</div>
	)
}

ArticleTitle.propTypes = {
	item: PropTypes.object.isRequired,
	dotYear: PropTypes.any,
}

export default ArticleTitle
