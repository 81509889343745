import './style.css'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Button } from 'react-bootstrap'
import InboundInfo from 'components/InboundInfo'
import LoadingSpinner from 'components/LoadingSpinner'
import FloatingButtonWrapper from 'components/FloatingButtonWrapper'
import SupplierInfo from 'components/SupplierInfo'
import FloatingFilterButton from 'components/FloatingFilterButton'
import If from 'components/If'
import InboundDeliverySearchForm from 'components/InboundDeliverySearchForm'
import FloatingWindow from 'components/FloatingWindow'
import { useLazyGetSuppliersQuery, useLazyGetPurchaseOrderDeliveriesQuery } from 'api/inboundDeliverysApi'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'features/MessageBox/duck'
import { setValue } from './inboundDeliverySlice'

const InboundDeliveryList = () => {
	const dispatch = useDispatch()

	const [showFilter, setShowFilter] = useState(false)

	const [triggerGetSuppliers, { data: suppliers, isLoading: loadingSuppliers }] = useLazyGetSuppliersQuery()
	const [triggerGetPurchaseOrderDeliveries, { data: inboundDeliverys, isLoading: loadingDeliveries, isError }] = useLazyGetPurchaseOrderDeliveriesQuery()

	const {
		supplier,
		ean,
		supplierArticleNumber,
		waybillNumber,
		supplierOrderNumber,
		sort,
		inboundDeliveryType,
	} = useSelector((state) => state.inboundDelivery)

	const sectionHeader = useRef('Inbound Deliveries')
	const navigate = useNavigate()

	const onChange = (name, value) => {
		dispatch(setValue({ name, value }))
	}

	const onSubmit = (e) => {
		if (e) e.preventDefault()

		if (((ean.length > 0 && ean.length < 3) ||
			(supplierArticleNumber.length > 0 && supplierArticleNumber.length < 3) ||
			(supplierOrderNumber.length > 0 && supplierOrderNumber.length < 2) ||
			(waybillNumber.length > 0 && waybillNumber.length < 3)) && !supplier) {
			dispatch(showMessage('Error', 'Please enter at least 3 characters'))
		} else {
			triggerGetPurchaseOrderDeliveries({
				supplierId: supplier,
				ean: ean,
				supplierArticleId: supplierArticleNumber,
				waybillNumber: waybillNumber,
				supplierOrderNumber: supplierOrderNumber,
				sort: sort
			})
			setShowFilter(false)
		}
	}

	const onClose = () => {
		navigate(-1)
	}

	const onFilterClicked = () => {
		setShowFilter(true)
	}

	const onDeliveryClicked = (item) => {
		navigate(`/inboundDelivery/${item.sequenceNumber}`)
	}

	const goHome = () => {
		navigate('/')
	}

	const hideInboundDeliveryRow = (deliveryType) => {
		switch (inboundDeliveryType) {
			case 'purchaseOrder':
				if (deliveryType !== 0) return 'hidden'
				break
			case 'customerReturn':
				if (deliveryType !== 1) return 'hidden'
				break
			default:
				return ''
		}
	}

	useEffect(() => {
		const payload = {
			supplierId: supplier,
			ean: ean,
			supplierArticleId: supplierArticleNumber,
			waybillNumber: waybillNumber,
			supplierOrderNumber: supplierOrderNumber,
			sort: sort
		}
		triggerGetPurchaseOrderDeliveries(payload)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (isError) dispatch(showMessage('Error', 'An error occurred while fetching deliveries'))
	}, [isError, dispatch])

	useEffect(() => {
		if (showFilter) {
			// Fetch suppliers data when filter is opened
			triggerGetSuppliers()
		}
	}, [showFilter, triggerGetSuppliers])

	useEffect(() => {
		switch (inboundDeliveryType) {
			case 'purchaseOrder':
				sectionHeader.current = 'Purchase Orders'
				break
			case 'customerReturn':
				sectionHeader.current = 'Customer Returns'
				break
			default:
				sectionHeader.current = 'Inbound Deliveries'
		}
	}, [inboundDeliveryType])

	const loading = (loadingDeliveries || loadingSuppliers)

	return (
		<>
			<Container className="inboundDeliverysList">
				{loading && <LoadingSpinner />}
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<h3>{sectionHeader.current}</h3>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col xs={12} sm={12}>
						<Row className="justify-content-center">
							{inboundDeliverys?.length
								? inboundDeliverys.map((item) => (
									<Card
										key={item.sequenceNumber}
										onClick={() => onDeliveryClicked(item)}
										className={hideInboundDeliveryRow(item.deliveryType)}
									>
										<Card.Body>
											<InboundInfo item={item} />
											<SupplierInfo supplier={item.supplier} supplierOrderId={item.supplierOrderId} />
										</Card.Body>
									</Card>
								))
								: ''}
						</Row>
					</Col>
				</Row>
			</Container>

			<If condition={showFilter}>
				<FloatingWindow className="justify-content-center" closeOutSide={false} draggable={false} showHeader={true} fullSize={true}>
					<InboundDeliverySearchForm
						onSubmit={onSubmit}
						onBack={onClose}
						onChange={onChange}
						suppliers={suppliers}
						supplier={supplier}
						ean={ean}
						supplierArticleNumber={supplierArticleNumber}
						waybillNumber={waybillNumber}
						inboundDeliveryType={inboundDeliveryType}
						supplierOrderNumber={supplierOrderNumber}
					/>
				</FloatingWindow>
			</If>

			<FloatingButtonWrapper>
				<FloatingFilterButton
					positionX='middle'
					positionY='bottom'
					btnState={showFilter}
					onClick={onFilterClicked}
					title='Filter and Search'
				/>
				<Button variant="danger" size="lg" onClick={goHome}>
					Home
				</Button>
			</FloatingButtonWrapper>
		</>
	)
}

export default InboundDeliveryList
