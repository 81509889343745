import { apiBase } from './apiBase'

export const loaderDimensionsApi = apiBase.injectEndpoints({
	endpoints: (builder) => ({
		getLoaderTyreDimensions: builder.query({
			query: () => 'loaderTyreDimensions'
		}),
	}),
})

export const {
	useGetLoaderTyreDimensionsQuery,
	useLazyGetLoaderTyreDimensionsQuery
} = loaderDimensionsApi