import { configureStore } from '@reduxjs/toolkit'
import fetchMiddleware from './fetchMiddleware'
import unauthorizedMiddleware from './unauthorizedMiddleware'
import { apiBase } from 'api/apiBase'
import app from 'features/App/appSlice'
import login from 'features/Login/loginSlice'
import articleStockLocationAdd from 'features/ArticleStockLocationAdd/articleStockLocationAddSlice'
import articleStockLocationList from 'features/ArticleStockLocationList/duck'
import messageBox from 'features/MessageBox/duck'
import printing from 'features/Printing/duck'
import connectionReducer from '../features/App/connectionSlice'
import barCodeScanner from 'features/BarCodeScanner/barCodeScannerSlice'
import inboundDelivery from 'features/InboundDelivery/inboundDeliverySlice'

export const store = configureStore({
	reducer: {
		app,
		login,
		articleStockLocationAdd,
		articleStockLocationList,
		messageBox,
		printing,
		barCodeScanner,
		inboundDelivery,
		connection: connectionReducer,
		[apiBase.reducerPath]: apiBase.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			thunk: {
				// extraArgument: {
				// 	cookies: context?.req?.headers?.cookie
				// },
			},
		})
			.concat([
				fetchMiddleware,
				unauthorizedMiddleware,
				apiBase.middleware,
			]),
	devTools: process.env.NODE_ENV !== 'production',
})