import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	connectionError: false,
}

const connectionSlice = createSlice({
	name: 'connection',
	initialState,
	reducers: {
		setConnectionError: (state, action) => {
			state.connectionError = action.payload
		},
	},
})

export const {
	setConnectionError,
} = connectionSlice.actions

export default connectionSlice.reducer