import './style.css'

import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card'
import ArticleTitle from 'components/ArticleTitle'
import If from 'components/If'
import Button from 'react-bootstrap/Button'
import NumpadInput from 'components/NumpadInput/NumpadInput'
import propTypes from 'prop-types'

const StockAddForm = ({
	articleId,
	article,
	lockArticle,
	newQuantity,
	stockLocation,
	lockLocation,
	dotYear,
	onChange,
	onSubmit,
	onBack,
}) => {

	const [articleIdValue, setArticleIdValue] = React.useState(articleId)
	const [errors, setErrors] = React.useState({})

	const onChangeHandler = (name, value) => {
		onChange(name, value)
	}

	const onCloseArticleNumpad = () => {
		onChange('articleId', articleIdValue)
	}

	const onSubmitHandler = (e) => {
		e.preventDefault()

		// valdiate dot between 2015 and current year
		if (dotYear < 2015 || dotYear > new Date().getFullYear()) {
			setErrors({ ...errors, dotYear: 'Invalid DOT Year' })
			return
		}

		onSubmit()
	}

	return (
		<Container className="stockAddForm">
			<Row className="justify-content-center">
				<Col xs={12} sm={12}>
					<If condition={article}>
						<Row className="justify-content-center">
							<Card style={{ width: '32rem' }}>
								<Card.Header>
									Article Description [{article ? article.articleId : ''}]
								</Card.Header>
								<Card.Body>
									{article ? (
										<ArticleTitle item={article} dotYear={dotYear} />
									) : (
										''
									)}
								</Card.Body>
							</Card>
						</Row>
					</If>

					<If condition={lockLocation}>
						<Row className="justify-content-center">
							<Card style={{ width: '32rem' }}>
								<Card.Header>Stock Location</Card.Header>
								<Card.Body>{stockLocation}</Card.Body>
							</Card>
						</Row>
					</If>

					<If condition={!lockArticle}>
						<Form.Group>
							<Row className="justify-content-center">
								<Form.Label column="xxl">Article ID</Form.Label>
								<Col xs={7}>
									<NumpadInput
										name="articleId"
										type="number"
										value={articleIdValue}
										size="xxl"
										className="editable"
										onChange={(_, value) => setArticleIdValue(value)}
										onCloseNumpad={onCloseArticleNumpad}
										readOnly
									/>
								</Col>
							</Row>
						</Form.Group>
					</If>

					<If condition={!lockLocation}>
						<Form.Group>
							<Row className="justify-content-center">
								<Form.Label column="xxl">Location</Form.Label>
								<Col xs={7}>
									<NumpadInput
										name="stockLocation"
										type="text"
										value={stockLocation}
										size="xxl"
										className="editable"
										onChange={onChangeHandler}
										showStockButton={true}
										readOnly
									/>
								</Col>
							</Row>
						</Form.Group>
					</If>

					<Form.Group>
						<Row className="justify-content-center">
							<Form.Label column="xxl">Quantity</Form.Label>
							<Col xs={7}>
								<NumpadInput
									name="newQuantity"
									type="number"
									value={newQuantity}
									size="xxl"
									className="editable"
									onChange={onChangeHandler}
									readOnly
								/>
							</Col>
						</Row>
					</Form.Group>

					<Form.Group>
						<Row className="justify-content-center">
							<Form.Label column="xxl">DOT Year</Form.Label>
							<Col xs={7}>
								<NumpadInput
									name="dotYear"
									type="number"
									value={dotYear}
									size="xxl"
									className="editable"
									onChange={onChangeHandler}
									readOnly
									error={errors.dotYear}
								/>
							</Col>
						</Row>
					</Form.Group>

					<Row className="justify-content-center">
						<Button
							variant="success"
							size="lg"
							onClick={onSubmitHandler}
							type="submit"
						>
							Save
						</Button>
					</Row>

					<Row className="justify-content-center">
						<Button variant="danger" size="lg" onClick={onBack}>
							Back
						</Button>
					</Row>
				</Col>
			</Row>
		</Container>
	)
}

StockAddForm.propTypes = {
	articleId: propTypes.number,
	article: propTypes.object,
	lockArticle: propTypes.bool,
	newQuantity: propTypes.number,
	stockLocation: propTypes.string,
	lockLocation: propTypes.bool,
	dotYear: propTypes.number,
	onChange: propTypes.func,
	onSubmit: propTypes.func,
	onBack: propTypes.func,
}

export default StockAddForm
